import React, { useContext } from "react";
import {
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { ThemeContext } from "../../context/ThemeContex";
import IHomiesHeader from "../Homepage/iHomies-header";
import IHomiesFooter from "../Homepage/iHomies-footer";

const TermsAndConditions = () => {
  const { themeSetting }: any = useContext(ThemeContext);
  return (
    <Box>
      <IHomiesHeader />

      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" gutterBottom>
            Terms and Conditions
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography paragraph>
            Welcome to {themeSetting?.title}! By accessing and using this
            website, you agree to comply with and be bound by the following
            terms and conditions. If you disagree with any part of these terms,
            please do not use our website.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Service Description
          </Typography>
          <Typography paragraph>
            {themeSetting?.title} is a chatbot designed to assist with business
            acquisitions using artificial intelligence. The service offers
            various modes of interaction tailored to different types of buyers
            and sellers. These modes include expert guidance on SaaS
            acquisitions, business valuations, eCommerce acquisitions, budget
            purchases under $10,000, and more.
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. User Roles and Responsibilities
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Buyer (SaaS): As a Buyer, you will receive guidance from our Expert AI SaaS Buyer on acquiring SaaS businesses." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Buyer (Valuation): You will receive assistance in evaluating potential acquisitions, focusing on accurate valuations." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Buyer (eCommerce): Our Expert AI eCommerce Buyer will help you identify and evaluate eCommerce businesses." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Buyer (Under $10k): You will be guided through acquisitions under $10,000 by our Expert AI Budget Buyer." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seller (SaaS): As a Seller, you will be assisted in preparing your SaaS business for sale." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seller (SaaS - Create Pitch): You will receive help creating compelling pitches for your SaaS business." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seller (eCommerce): You will be guided in optimizing your eCommerce business for sale." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seller (Mobile App): Our Expert AI Mobile App Seller will help you navigate the process of selling your mobile app." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Seller (Valuation): You will be provided with accurate valuations and strategies to enhance your business value before sale." />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            4. Payment Plans and Credits
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Flexi Plan: For $1, you receive 2 credits. Each conversation with the chatbot requires 1 credit, offering you the flexibility to pay as you go." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Monthly Plan: For $15, you receive 60 credits, providing more value and allowing you to start multiple conversations with the chatbot throughout the month." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Free Credits: Upon registration, we provide 3 free credits to help you get started with our service." />
            </ListItem>
            <ListItem>
              <ListItemText primary="No Refunds: All purchases of credits are final, and no refunds will be issued under any circumstances." />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            5. Limitations of Liability
          </Typography>
          <Typography paragraph>
            {themeSetting?.title} is an MVP (Minimum Viable Product), and while
            we strive to provide accurate and helpful guidance, we do not
            guarantee the success of any business acquisition or sale
            facilitated through our platform. Users are responsible for
            conducting their due diligence.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Intellectual Property
          </Typography>
          <Typography paragraph>
            All content on {themeSetting?.title}, including but not limited to
            text, images, graphics, and logos, are the intellectual property of
            {themeSetting?.title} or its licensors. Unauthorized use of this
            content is prohibited.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. User Conduct
          </Typography>
          <Typography paragraph>
            Users agree to use {themeSetting?.title} only for lawful purposes
            and in a manner that does not infringe the rights of, or restrict or
            inhibit the use of this website by any third party.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. Privacy Policy
          </Typography>
          <Typography paragraph>
            Your privacy is important to us. Please review our Privacy Policy to
            understand how we collect, use, and protect your personal
            information.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9. Modifications to Terms
          </Typography>
          <Typography paragraph>
            {themeSetting?.title} reserves the right to modify these terms at
            any time. Changes will be posted on this page, and it is your
            responsibility to review these terms periodically.
          </Typography>

          <Typography variant="h6" gutterBottom>
            10. Contact Information
          </Typography>
          <Typography paragraph>
            For any questions regarding these terms, please contact us at{" "}
            <a href="mailto:cto.devprojects@gmail.com">
              cto.devprojects@gmail.com
            </a>
          </Typography>

          <Typography variant="h6" gutterBottom>
            11. Disclaimer
          </Typography>
          <Typography paragraph>
            Please note that {themeSetting?.title} is continuously being
            improved. As an MVP, certain features may be in development or
            subject to change. We appreciate your understanding as we work to
            enhance the platform.
          </Typography>
        </Box>
      </Container>
      <IHomiesFooter />
    </Box>
  );
};

export default TermsAndConditions;
