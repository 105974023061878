import { Backdrop, CircularProgress } from "@mui/material";
import { useContext } from "react";
import { LoaderContext } from "../../../context/LoaderContext";

export const Loader = () => {
  const { open, handleCloseLoader }: any = useContext(LoaderContext);
  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      open={open}
      onClick={handleCloseLoader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
