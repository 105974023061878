import React, { useContext } from "react";
import {
  Box,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { ThemeContext } from "../../context/ThemeContex";
import IHomiesHeader from "../Homepage/iHomies-header";
import IHomiesFooter from "../Homepage/iHomies-footer";

const PrivacyPolicy = () => {
  const { themeSetting }: any = useContext(ThemeContext);
  return (
    <Box>
      <IHomiesHeader />

      <Container maxWidth="lg">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" gutterBottom>
            Privacy Policy
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography paragraph>
            {themeSetting?.title} is committed to protecting your privacy. This
            Privacy Policy outlines how we collect, use, and safeguard your
            personal information when you use our website and services. By using
            {themeSetting?.title}, you consent to the practices described in
            this policy.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Information We Collect
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Personal Information: When you register on our platform, we may collect personal information such as your name, email address, payment details, and other information necessary to provide our services." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Usage Data: We may collect information about how you interact with our website and services, including IP address, browser type, pages visited, and the time spent on those pages." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Cookies: We use cookies and similar tracking technologies to enhance your experience on our website. Cookies help us remember your preferences and understand how you use our services." />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            3. How We Use Your Information
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Service Delivery: We use your personal information to provide and maintain our services, including processing transactions, managing your account, and responding to your inquiries." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Personalization: We may use the information we collect to personalise your experience on our platform, including suggesting relevant content or features." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Communication: We may use your email address to send you updates, promotional materials, and other information related to our services. You can opt-out of these communications at any time." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Analytics: We use aggregated data to analyse user behaviour, improve our website and services, and develop new features." />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            4. Sharing Your Information
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Third-Party Service Providers: We may share your personal information with third-party service providers who assist us in operating our website, conducting business, or providing services to you. These providers are contractually obligated to protect your information and use it only for the purposes specified by {themeSetting?.title}." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Legal Compliance: We may disclose your personal information if required by law, in response to a court order, or to protect the rights, property, or safety of {themeSetting?.title}, our users, or others." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Business Transfers: In the event of a merger, acquisition, or sale of all or part of our assets, your personal information may be transferred to the new owner." />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            5. Data Security
          </Typography>
          <Typography paragraph>
            We take reasonable measures to protect your personal information
            from unauthorized access, disclosure, alteration, or destruction.
            However, no method of transmission over the Internet or method of
            electronic storage is 100% secure, and we cannot guarantee absolute
            security.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Your Rights
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Access and Correction: You have the right to access and update your personal information stored by us. You can do this by logging into your account or contacting us directly." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Data Deletion: You can request the deletion of your personal information from our systems. Please note that certain information may be retained as required by law or for legitimate business purposes." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Opt-Out: You can opt-out of receiving promotional communications from us by following the instructions provided in the emails or contacting us directly." />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            7. Children’s Privacy
          </Typography>
          <Typography paragraph>
            {themeSetting?.title} is not intended for use by individuals under
            the age of 18. We do not knowingly collect personal information from
            children under 18. If we become aware that we have collected
            personal information from a child under 18, we will take steps to
            delete such information.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. International Data Transfers
          </Typography>
          <Typography paragraph>
            Your personal information may be transferred to and processed in
            countries other than your own. By using our services, you consent to
            the transfer of your information to countries with different data
            protection laws.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9. Changes to This Privacy Policy
          </Typography>
          <Typography paragraph>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page, and the date of the latest revision
            will be indicated at the top. We encourage you to review this policy
            periodically to stay informed about how we are protecting your
            information.
          </Typography>

          <Typography variant="h6" gutterBottom>
            10. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at{" "}
            <a href="mailto:cto.devprojects@gmail.com">
              cto.devprojects@gmail.com
            </a>
          </Typography>
        </Box>
      </Container>
      <IHomiesFooter />
    </Box>
  );
};

export default PrivacyPolicy;
