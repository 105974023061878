import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "./http-server";
const baseUrl = process.env.REACT_APP_API_URL;

const get = (url: string, signal?: AbortSignal) => {
  return axiosInstance.get(baseUrl + `${url}`, { signal });
};
const deleteData = (url: string) => {
  return axiosInstance.delete(baseUrl + `${url}`, {});
};

const post = (url: string, data?: any) => {
  return axiosInstance.post(baseUrl + url, data);
};
const postImage = (url: string, data: any) => {
  return axiosInstance.post(baseUrl + url, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const postdoc = (url: string, data: any) => {
  return axiosInstance.post(baseUrl + url, data, {
    responseType: "blob",
  });
};

const HttpService = {
  get,
  deleteData,

  postImage,
  postdoc,
  post,
};

export default HttpService;
