import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { API_ROUTES, PAGE_ROUTES } from "../../constant";
import { Link, useNavigate } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import Style from "../../style/Style";
import * as Yup from "yup";
import { Formik } from "formik";
import HttpService from "../../hooks/Https-services";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import Logo from "../../../assets/imeges/logo192.png";

import { ThemeConfig } from "../../types";
import { ThemeContext } from "../../context/ThemeContex";
export function SignUp() {
  const [isLoading, setIsLoading] = React.useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobile_no: Yup.string().required("Phone number is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[@$!%*?&]/,
        "Password must contain at least one special character"
      )
      .required("Password is required"),
  });
  const { themeSetting }: { themeSetting: ThemeConfig } =
    React.useContext(ThemeContext);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        ...Style?.LoginForm?.MainContainer,
        background: `url(${themeSetting?.login_bg_image})`,
      }}
    >
      <Container
        component="main"
        sx={Style.LoginForm?.SubContainer}
        maxWidth="xs"
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            onClick={() => navigate(PAGE_ROUTES.HomePage)}
            sx={{
              m: 1,
              width: 75,
              height: 75,
              bgcolor: "secondary.main",
              cursor: "pointer",
            }}
          >
            <img
              src={themeSetting?.logo}
              width={"100%"}
              style={{ objectFit: "cover" }}
              alt="logo"
            />
          </Avatar>
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            {themeSetting?.title} Registration
          </Typography>
          <Formik
            initialValues={{ name: "", email: "", mobile_no: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              // Handle form submission
              setIsLoading(true);
              const formData = new FormData();
              formData.append("email", values.email);
              formData.append("name", values.name);
              formData.append("password", values.password);
              formData.append("phone_no", values.mobile_no);
              try {
                const res: any = await HttpService.post(
                  API_ROUTES.Register,
                  formData
                );
                if (res) {
                  navigate(PAGE_ROUTES.Login);
                  resetForm();
                }
                toast.success(
                  "Congratulations! You've successfully registered."
                );
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                console.log(error);
              }
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              values,
            }) => (
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="given-name"
                      name="name"
                      required
                      fullWidth
                      id="name"
                      label="Name"
                      onChange={handleChange}
                      error={touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MuiTelInput
                      defaultCountry="IN"
                      autoComplete="given-mobile_no"
                      id="mobile_no"
                      name="mobile_no"
                      required
                      value={values.mobile_no}
                      onChange={(value) => setFieldValue("mobile_no", value)}
                      error={touched.mobile_no && !!errors.mobile_no}
                      helperText={touched.mobile_no && errors.mobile_no}
                      fullWidth
                      label="Phone Number"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      onChange={handleChange}
                      autoComplete="email"
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      onChange={handleChange}
                      label="Password"
                      type="password"
                      onBlur={handleBlur}
                      id="password"
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      autoComplete="new-password"
                    />
                  </Grid>
                </Grid>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isSubmitting}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign Up
                </LoadingButton>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link to={PAGE_ROUTES.Login}>
                      <Typography variant="body2">
                        Already have an account? Sign in
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
}
