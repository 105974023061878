import { Box, Chip } from "@mui/material";

export const FEATURE = () => {
  return (
    <Box
      sx={{
        borderRadius: 7,
        width: "fit-content",
        backgroundColor: "#F3F7FB",
      }}
    >
      <Chip
        sx={{
          textAlign: "center",
          p: 1,
          ".MuiChip-label": {
            color: "primary.main",
          },

          fontFamily: "Be Vietnam Pro",
        }}
        label="FEATURE"
      />
    </Box>
  );
};
