import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { API_ROUTES, PAGE_ROUTES } from "../../constant";
import Style from "../../style/Style";
import * as Yup from "yup";
import { Formik, FormikHelpers, FormikValues } from "formik";
import HttpService from "../../hooks/Https-services";
import {
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { LoadingButton } from "@mui/lab";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { setAuth } from "../../hooks/http-server";
import { toast } from "react-toastify";
import Logo from "../../../assets/imeges/logo192.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ThemeContext } from "../../context/ThemeContex";
import { ThemeConfig } from "../../types";
// TODO remove, this demo shouldn't need to reset the theme.

export function SignIn() {
  const [isOtpSent, setSendOtp] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const validationSchemaOtp = Yup.object({
    otp: Yup.string()
      .length(4, "OTP must be exactly 4 digits")
      .matches(/^[0-9]+$/, "OTP must be a number")
      .required("OTP is required"),
  });
  interface FormValues {
    email: string;
    password: string;
  }
  interface FormValuesOtp {
    otp: string;
  }
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const { themeSetting }: { themeSetting: ThemeConfig } =
    React.useContext(ThemeContext);
  const handleSendOtpAgain = async () => {
    try {
    } catch (error) {}
  };

  return (
    <Box
      sx={{
        ...Style?.LoginForm?.MainContainer,
        background: `url(${themeSetting?.login_bg_image})`,
      }}
    >
      <Container
        component="main"
        sx={Style.LoginForm?.SubContainer}
        maxWidth="xs"
      >
        <CssBaseline />
        {!isOtpSent ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              onClick={() => navigate(PAGE_ROUTES.HomePage)}
              sx={{
                m: 1,
                width: 75,
                height: 75,
                bgcolor: "secondary.main",
                cursor: "pointer",
              }}
            >
              <img
                src={themeSetting?.logo}
                width={"100%"}
                style={{ objectFit: "cover" }}
                alt="logo"
              />
            </Avatar>
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              Welcome to {themeSetting?.title}
            </Typography>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={async (
                values: FormikValues,
                { resetForm }: FormikHelpers<FormValues>
              ) => {
                // Handle form submission
                setIsLoading(true);
                const formData = new FormData();
                formData.append("email", values.email);
                formData.append("password", values.password);
                try {
                  const res: any = await HttpService.post(
                    API_ROUTES.Login,
                    formData
                  );
                  console.log(res);
                  if (res) {
                    if (res?.data?.data?.varify_status) {
                      setAuth({
                        Token: res?.data?.data?.token,
                        Email: res?.data?.data?.email,
                        Name: res?.data?.data?.name,
                        Phone: res?.data?.data?.mobile_no,
                      });
                      navigate(PAGE_ROUTES.Dashboard);
                      setSendOtp(false);
                      toast.success("Login successful! Enjoy your experience.");
                    } else {
                      setSendOtp(true);
                      searchParams.set("email", values.email);

                      setSearchParams(searchParams);
                      toast.success("OTP sent successfully");
                      resetForm();
                    }
                  }
                  setIsLoading(false);
                } catch (error) {
                  setIsLoading(false);
                }
              }}
            >
              {({
                errors,
                touched,
                handleSubmit,
                handleBlur,
                handleChange,
                isSubmitting,
              }) => (
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    onChange={handleChange}
                    autoComplete="email"
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="password"
                    autoComplete="current-password"
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                  />

                  <LoadingButton
                    loading={isLoading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign In
                  </LoadingButton>
                  <Grid container>
                    <Grid item xs>
                      <Link to={PAGE_ROUTES.ForgotPassword}>
                        <Typography variant="body2">
                          Forgot password?
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to={PAGE_ROUTES.Register}>
                        <Typography variant="body2">
                          {"Don't have an account? Sign Up"}
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Formik>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexGrow: 1,
                width: 1,
              }}
            >
              <IconButton onClick={() => navigate(-1)}>
                <KeyboardBackspaceIcon
                  sx={{
                    color: "text.primary",
                  }}
                />
              </IconButton>
            </Box>
            <Avatar
              onClick={() => navigate(PAGE_ROUTES.HomePage)}
              sx={{
                m: 1,
                width: 75,
                height: 75,
                bgcolor: "secondary.main",
                cursor: "pointer",
              }}
            >
              <img
                src={themeSetting?.logo}
                width={"100%"}
                style={{ objectFit: "cover" }}
                alt="logo"
              />
            </Avatar>
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              Verify Otp
            </Typography>
            <Formik
              initialValues={{ otp: "" }}
              validationSchema={validationSchemaOtp}
              onSubmit={async (
                values: FormikValues,
                { resetForm }: FormikHelpers<FormValuesOtp>
              ) => {
                // Handle form submission
                setIsLoading(true);
                const formData = new FormData();
                const email: string | null = searchParams.get("email");
                formData.append("email", email || "");
                formData.append("otp", values.otp);
                try {
                  const res: any = await HttpService.post(
                    API_ROUTES.VerifyOtp,
                    formData
                  );
                  if (res) {
                    setAuth({
                      Token: res?.data?.data?.token,
                      Email: res?.data?.data?.email,
                      Name: res?.data?.data?.name,
                      Phone: res?.data?.data?.mobile_no,
                    });
                    navigate(PAGE_ROUTES.Dashboard);
                    setSendOtp(false);
                    toast.success("Login successful! Enjoy your experience.");
                    resetForm();
                  }
                  setIsLoading(false);
                } catch (error) {
                  setIsLoading(false);
                  console.log(error);
                }
                console.log(values);
              }}
            >
              {({
                errors,
                touched,
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
                isSubmitting,
                values,
              }) => {
                console.log(errors);
                return (
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                  >
                    <MuiOtpInput
                      value={values.otp}
                      autoFocus
                      length={4}
                      onChange={(newOtp) => {
                        setFieldValue("otp", newOtp);
                      }}
                      TextFieldsProps={{
                        placeholder: "0",
                        error: !!errors.otp,
                        autoComplete: "off",
                      }}
                    />
                    {!!errors.otp && (
                      <FormHelperText
                        sx={{ color: "#EF4444", fontSize: "12px" }}
                      >
                        {errors.otp
                          ? errors.otp
                          : "Invalid code entered. Please try again."}
                      </FormHelperText>
                    )}
                    <LoadingButton
                      loading={isLoading}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Verify
                    </LoadingButton>
                    {/* <Grid container>
                      <Grid item xs>
                        <Button onClick={handleSendOtpAgain}>
                          <Typography variant="body2">Resend Otp</Typography>
                        </Button>
                      </Grid>
                    </Grid> */}
                  </Box>
                );
              }}
            </Formik>
          </Box>
        )}
      </Container>
    </Box>
  );
}
