import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import { useContext } from "react";

import "./index.css";

import { ThemeContext } from "../../../context/ThemeContex";
function IHomiesTestimonials() {
  const { themeSetting }: any = useContext(ThemeContext);

  return (
    <Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "170px",
              borderRadius: 7,
              backgroundColor: "#F3F7FB",
              my: 2,
            }}
          >
            <Typography
              fontWeight={600}
              sx={{
                textAlign: "center",
                borderRadius: 7,
                width: "170px",
                p: 0.5,

                backgroundColor: "#FFF",
                color: "primary.main",

                WebkitBackgroundClip: "text",
                fontFamily: "Be Vietnam Pro",
              }}
            >
              TESTIMONIALS
            </Typography>
          </Box>
          <Typography
            variant="h1"
            sx={{
              textAlign: "center",
              mb: 4,
              color: (theme) =>
                theme.palette.mode === "dark" ? "white" : "#02015A",
              lineHeight: "44px",
              fontWeight: 600,
              fontFamily: "Be Vietnam Pro",
            }}
            className="testimonials-scroll"
            id="testimonials-scroll"
          >
            Don’t take our word for it
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} spacing={2} sm={6} md={4}>
                <Card sx={{ maxWidth: 345, padding: 2, borderRadius: 4 }}>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating value={5} readOnly />
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        {themeSetting?.title} has revolutionized the way I
                        approach business acquisitions. The AI-driven insights
                        have been invaluable in helping me make informed
                        decisions quickly and efficiently. I highly recommend
                        this platform to anyone serious about business
                        acquisitions
                      </Typography>
                    </Box>
                    <Grid item xs mt={2}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Soham Thaker
                      </Typography>
                      {/* <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontFamily: "Be Vietnam Pro" }}
                        >
                          Junior Accountant at VxTech
                        </Typography> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} spacing={2} sm={6} md={4}>
                <Card
                  sx={{ maxWidth: 345, padding: 2, borderRadius: 4, height: 1 }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating value={5} readOnly />
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        The ease of use and the depth of analysis provided by
                        {themeSetting?.title} is unparalleled. Whether it's SaaS
                        or eCommerce, the expert guidance from the AI has made
                        my acquisition process smoother and more successful.
                      </Typography>
                    </Box>
                    <Grid item xs mt={2}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Siddhi Kanyalkar
                      </Typography>
                      {/* <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontFamily: "Be Vietnam Pro" }}
                        >
                          Senior Accountant at Vetar
                        </Typography> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} spacing={2} sm={6} md={4}>
                {" "}
                <Card
                  sx={{
                    height: 1,
                    maxWidth: 345,
                    padding: 2,
                    borderRadius: 4,
                    flexGrow: 1,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating value={5} readOnly />
                      </Grid>
                    </Box>
                    <Box mt={3.5}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        As someone new to the world of business acquisitions, I
                        was initially overwhelmed. {themeSetting?.title} changed
                        that for me. The platform's intuitive interface and
                        expert AI advisors have been a game-changer in building
                        my confidence and making sound investment decisions.
                      </Typography>
                    </Box>
                    <Grid item xs mt={4.5}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Sangram Tambe
                      </Typography>
                      {/* <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontFamily: "Be Vietnam Pro" }}
                        >
                          Salesman at Formible
                        </Typography> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} spacing={2} sm={6} md={4}>
                <Card
                  sx={{
                    maxWidth: 345,
                    padding: 2,
                    borderRadius: 4,
                    height: "100%",
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating value={5} readOnly />
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        {themeSetting?.title} has taken the guesswork out of
                        valuing potential acquisitions. The AI's accuracy and
                        detailed guidance have saved me time and money, making
                        it an indispensable tool in my acquisition strategy
                      </Typography>
                    </Box>
                    <Grid item xs mt={2}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Dev Shah
                      </Typography>
                      {/* <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontFamily: "Be Vietnam Pro" }}
                        >
                          Junior Accountant
                        </Typography> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} spacing={2} sm={6} md={4}>
                <Card sx={{ maxWidth: 345, padding: 2, borderRadius: 4 }}>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating value={5} readOnly />
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        Selling my eCommerce business was made so much easier
                        with {themeSetting?.title}. The AI's assistance in
                        creating a compelling pitch and optimising my business
                        for sale helped me achieve a better deal than I ever
                        expected
                      </Typography>
                    </Box>
                    <Grid item xs mt={2}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Kashi Shekhda
                      </Typography>
                      {/* <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontFamily: "Be Vietnam Pro" }}
                        >
                          Sales Consultant
                        </Typography> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} spacing={2} sm={6} md={4}>
                <Card sx={{ maxWidth: 345, padding: 2, borderRadius: 4 }}>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating value={5} readOnly />
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        The ability to get expert advice on acquisitions under
                        $10k has been fantastic. {themeSetting?.title} has
                        opened up opportunities I wouldn't have considered
                        otherwise, and the platform's flexibility makes it a joy
                        to use.
                      </Typography>
                    </Box>
                    <Grid item xs mt={2}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Dakshesh Thaker
                      </Typography>
                      {/* <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontFamily: "Be Vietnam Pro" }}
                        >
                          Sales Consultant
                        </Typography> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default IHomiesTestimonials;
