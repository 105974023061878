import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import { Logout as MuiLogout, SvgIconComponent } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../../../../constant";
import { Logout } from "../../../../hooks/http-server";
import useCommonDetails from "../../../../hooks/useCommonDetails";
import MenuIcon from "@mui/icons-material/Menu";
import { Sidebar } from "../../Sidebar";
import { NavSectionContainerI } from "../../../../types";
import { useUpdateNavSectionContainer } from "../../Sidebar/helper";
import Profile from "../../../../assets/imeges/profile.png";
import useIsPhone from "../../../../hooks/usePhone";
import HistoryIcon from "@mui/icons-material/History";
export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const isPhone = useIsPhone();
  const open = Boolean(anchorEl);
  const navItems = useUpdateNavSectionContainer("admin");
  const userDetails = useCommonDetails();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeRoute = (route: string) => {
    navigate(route);
    handleClose();
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip arrow placement="top" title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, display: { xs: "none", md: "block" } }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar src={Profile} sx={{ width: 32, height: 32 }}></Avatar>
          </IconButton>
        </Tooltip>
        <Tooltip arrow placement="top" title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, display: { xs: "block", md: "none" } }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            maxHeight: 500,
            overflowY: "auto",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem> */}
        <MenuItem
          sx={{ display: { xs: "none", md: "flex" } }}
          onClick={() => handleChangeRoute(PAGE_ROUTES.MyAccount)}
        >
          <Avatar src={Profile}> </Avatar> My account
        </MenuItem>

        <Divider sx={{ display: { xs: "none", md: "block" } }} />
        {navItems.map((navSection, index) => {
          return navSection.sections?.map((section, sectionIndex) => {
            const IconComponent: SvgIconComponent = section.icon;
            return (
              <MenuItem
                sx={{ display: { xs: "flex", md: "none" } }}
                key={`${index}-${sectionIndex}`}
                onClick={() => navigate(section.path)}
              >
                <ListItemIcon>{<IconComponent />}</ListItemIcon>
                {section.label}
              </MenuItem>
            );
          });
        })}
        <MenuItem onClick={() => handleChangeRoute(PAGE_ROUTES.Chats)}>
          <ListItemIcon>
            <HistoryIcon fontSize="small" />
          </ListItemIcon>
          History
        </MenuItem>

        <MenuItem onClick={() => Logout()}>
          <ListItemIcon>
            <MuiLogout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
