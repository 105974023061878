import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Box,
  useTheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import { useStoreState } from "easy-peasy";
import { ReduxStoreI } from "../../models/redux-store";
import { ThemeContext } from "../../context/ThemeContex";
import { ThemeConfig } from "../../types";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES } from "../../constant";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CreateChatDialoag from "../Chats/ChatTable/CreateChatDialoag";
const StyledContainer = styled(Container)({
  marginTop: "50px",
});

const StyledCard = styled(Card)({
  marginBottom: "20px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const StyledHeader = styled(Typography)({
  marginBottom: "30px",
  fontWeight: "bold",
});

const StyledListItemText = styled(ListItemText)({});

export const Dashboard = () => {
  const { chatTypes } = useStoreState((stats: ReduxStoreI) => stats.Chats);
  const theme = useTheme();
  const [faq, setFaq] = useState([]);
  const [dashboardContent, setDashboardContent] = useState("");
  const isDark = theme.palette.mode === "dark" ? true : false;
  const { themeSetting }: { themeSetting: ThemeConfig } =
    React.useContext(ThemeContext);
  const GetFAQ = useCallback(async () => {
    try {
      const res = await HttpService.get(API_ROUTES.Faq);
      setFaq(res?.data?.data);
    } catch (error) {}
  }, []);
  const GetDashBoardContent = useCallback(async () => {
    try {
      const res = await HttpService.get(API_ROUTES.HomePageContent);
      setDashboardContent(res?.data?.data?.page_content);
    } catch (error) {}
  }, []);
  useEffect(() => {
    Promise.resolve([GetDashBoardContent(), GetFAQ()]);
  }, [GetFAQ, GetDashBoardContent]);
  return (
    <StyledContainer maxWidth="lg">
      <StyledHeader
        sx={{
          color: isDark ? "common.white" : "#333",
          textAlign: "center",
        }}
        variant="h2"
      >
        Welcome to {themeSetting?.title}
      </StyledHeader>
      <Typography variant="body1" paragraph>
        {themeSetting?.title} is a chatbot designed to assist with business
        acquisitions using artificial intelligence. You can start a new chat and
        decide the mode you want to chat with:
      </Typography>
      <List>
        {chatTypes?.map((el: any) => (
          <CreateChatDialoag
            filter={el?.instruction_id}
            component={
              <StyledCard>
                <Button sx={{ p: 0 }}>
                  <CardContent>
                    <ListItem
                      sx={{
                        gap: 1,
                        flexDirection: { xs: "column", md: "row" },
                      }}
                    >
                      {/* eslint-disable-next-line jsx-a11y/alt-text */}
                      <img src={el?.icon} width={100} />
                      <StyledListItemText
                        sx={{
                          color: isDark ? "common.white" : "#555",
                        }}
                        primary={
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {el?.role_name}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="body2"
                            sx={{ color: isDark ? "common.white" : "#555" }}
                          >
                            {el?.description}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </CardContent>
                </Button>
              </StyledCard>
            }
          />
        ))}
      </List>
      <Typography variant="body2" color="textSecondary" paragraph>
        Please note that this is just an MVP (Minimum Viable Product), and our
        team is continuously working towards improving it.
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: dashboardContent }}></div>
      {!!faq?.length && (
        <Box>
          <StyledHeader
            sx={{
              color: isDark ? "common.white" : "#333",
              mt: 2,
              textAlign: "center",
            }}
            variant="h2"
          >
            FAQ
          </StyledHeader>
          <List sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
            {faq?.map((el: any, index: number) => {
              return (
                <Box key={index + 1}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                    >
                      <Typography>{el?.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ textAlign: "start" }}>
                      <Typography>{el?.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
          </List>
        </Box>
      )}
    </StyledContainer>
  );
};
