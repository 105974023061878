import React, { useCallback, useEffect, useState } from "react";
import TansactionTable from "./TansactionTable";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES } from "../../constant";

export function Credit() {
  return (
    <div>
      <TansactionTable />
    </div>
  );
}
