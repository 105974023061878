import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { PrivateRoutesProps } from "../PrivateRoute";
import { Sidebar } from "../Components/Sidebar";
import { Button } from "@mui/material";
import { Header } from "../Components/Header";
import { Footer } from "../Components/Footer";
import { useStoreActions } from "easy-peasy";
import { ReduxStoreI } from "../../models/redux-store";
import { ThemeContext } from "../../context/ThemeContex";
import { ThemeConfig } from "../../types";

const drawerWidth = 255;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("md")]: {
    // Styles for screens larger than 'small'
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
  [theme.breakpoints.down("md")]: {
    // Styles for screens smaller than 'small'
    ...(open && {
      marginLeft: 0,
      width: "100%",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MainContainer({ children }: PrivateRoutesProps) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const { getAll, getAllChatType } = useStoreActions(
    (actions: ReduxStoreI) => actions.Chats
  );
  const { themeSetting }: { themeSetting: ThemeConfig } =
    React.useContext(ThemeContext);
  React.useEffect(() => {
    getAll();
    getAllChatType();
  }, [getAll, getAllChatType]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        sx={{
          bgcolor: "common.pageBackground",
        }}
        position="fixed"
        open={open}
      >
        <Toolbar
          sx={{
            "&.MuiToolbar-root": { pl: open ? 2 : 0 },
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
              height: "64px",
            }}
          >
            <Button
              color="inherit"
              onClick={handleDrawerOpen}
              aria-label="open drawer"
              sx={{
                bgcolor:
                  theme.palette.mode === "light"
                    ? themeSetting?.sidebar_light_color
                    : themeSetting?.sidebar_dark_color,
                marginRight: 1,
                borderRadius: "0px",
                flexGrow: 1,
                minWidth: "65px",
                ":hover": {
                  bgcolor:
                    theme.palette.mode === "light"
                      ? themeSetting?.sidebar_light_color
                      : themeSetting?.sidebar_dark_color,
                },
                ...(open && { display: "none" }),
              }}
            >
              <Typography
                variant="h2"
                sx={{ fontSize: 12, fontWeight: 800, color: "common.white" }}
              >
                {themeSetting?.sidemenu_title}
              </Typography>
            </Button>
          </Box>

          <Header />
        </Toolbar>
      </AppBar>
      <Drawer
        color="primary"
        sx={{
          ".MuiDrawer-paper": {
            bgcolor:
              theme.palette.mode === "light"
                ? themeSetting?.sidebar_light_color
                : themeSetting?.sidebar_dark_color,
          },

          display: { xs: "none", md: "block" },
        }}
        variant="permanent"
        open={open}
      >
        <DrawerHeader>
          <Typography
            variant="h2"
            sx={{
              fontSize: 18,
              fontWeight: 800,
              color: "common.white",
              lineHeight: "20px",
              textTransform: "uppercase",
              mr: 2,
            }}
          >
            {themeSetting?.title}
          </Typography>
          <IconButton
            sx={{ color: "common.white" }}
            onClick={handleDrawerClose}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <Divider />
        <List sx={{ p: 1 }}>
          <Sidebar open={open} />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 3,
          py: 1.5,
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <DrawerHeader />
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          {children}
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}
