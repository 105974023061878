import { SxProps } from "@mui/material";
import BgImage from "../../assets/imeges/bg.png";

const Style: { [key: string]: { [key: string]: SxProps } } = {
  LoginForm: {
    MainContainer: {
      backgroundPosition: "cover",
      backgroundSize: "cover",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    SubContainer: {
      display: "flex",
      px: 2,
      py: 3,
      borderRadius: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "fix-content",
      bgcolor: "common.pageBackground",
    },
  },
};
export default Style;
