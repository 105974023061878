import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import { Add } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import HttpService from "../../../hooks/Https-services";
import { API_ROUTES } from "../../../constant";
import moment from "moment";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { LoaderContext } from "../../../context/LoaderContext";

interface Data {
  pk: number | string;
  amount: number | string;
  transaction_type: number | string;
  transaction_id: number | string;
  transaction_at: number | string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly {
    pk: number | string;
    amount: number | string;
    transaction_type: number | string;
    transaction_id: number | string;
    transaction_at: number | string;
  }[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "pk",
    numeric: false,
    disablePadding: false,
    label: "Sr. No.",
  },
  {
    id: "transaction_id",
    numeric: true,
    disablePadding: false,
    label: "Transaction Id",
  },
  {
    id: "transaction_type",
    numeric: true,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "transaction_at",
    numeric: true,
    disablePadding: false,
    label: "Transaction At",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  handleChangeRefresh: () => void;
  isRefresh: boolean;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, handleChangeRefresh, isRefresh } = props;
  const [open, setOpen] = React.useState(false);
  const [credit, setCredit] = React.useState(0);
  const [creditError, setCreditError] = useState("");
  const [plans, setPlans] = useState([]);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(null);
  console.log(isSubscriptionActive);
  const [flexiPlan, setFlexiPlan] = useState<any>({});
  const [selectedPlan, setSelectedPlan] = useState("");
  const { userCredit, setUserCredit }: any = React.useContext(LoaderContext);
  const handleAddCredit = async () => {
    try {
      if (!credit && !selectedPlan) {
        setCreditError("Please enter value");
        return;
      } else if (credit < 2 && !selectedPlan) {
        setCreditError("Add minimum 2");
        return;
      }
      const formData = new FormData();
      console.log(flexiPlan);
      const amount = (flexiPlan?.amount * credit) / flexiPlan?.credit_point;
      if (credit) {
        formData.append("credit_amount", amount.toString());
        formData.append("plan_id", flexiPlan?.pk);
      } else if (selectedPlan) {
        formData.append("plan_id", selectedPlan);
      }

      const res = await HttpService.post(API_ROUTES.AddCredit, formData);
      window.location.href = res?.data?.data?.checkout_session_url;
    } catch (error) {}
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const GetPlans = useCallback(async () => {
    try {
      const res = await HttpService.get(API_ROUTES.GetPlans);
      console.log(res);
      const plans = res?.data?.data?.plan_list?.filter(
        (el: any) => el?.price_id
      );
      const flexiPlans = res?.data?.data?.plan_list?.filter(
        (el: any) => !el?.price_id
      );
      setFlexiPlan(flexiPlans[0]);
      setPlans(plans);
      setIsSubscriptionActive(res?.data?.data?.subscription_active);
    } catch (error) {}
  }, []);
  React.useEffect(() => {
    GetPlans();
  }, [GetPlans]);
  return (
    <Toolbar
      sx={{
        justifyContent: "space-between",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography variant="h6" id="tableTitle" component="div">
        Credit
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Tooltip title="Add Credit">
          <Button onClick={handleClickOpen} size="small" variant="contained">
            <Add />
          </Button>
        </Tooltip>

        <Dialog
          open={open}
          maxWidth="sm"
          sx={{
            ".MuiDialog-paper": {
              width: 1,
            },
          }}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Add Credit"}</DialogTitle>
          <DialogContent sx={{ py: 2 }}>
            <TextField
              sx={{ mt: 1 }}
              value={credit}
              helperText={creditError}
              error={creditError ? true : false}
              onChange={(e: any) => {
                setCredit(e.target.value);
                setSelectedPlan("");
                setCreditError("");
              }}
              label="Credit"
              placeholder="Credit"
              size="small"
              fullWidth
            />
            <Box sx={{ mt: 2 }}>
              <Grid spacing={2} container>
                <Grid item xs={3}>
                  <Button
                    onClick={() => {
                      setCredit(2);
                      setSelectedPlan("");
                    }}
                    fullWidth
                    variant="contained"
                  >
                    2
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    onClick={() => {
                      setCredit(5);
                      setSelectedPlan("");
                    }}
                    fullWidth
                    variant="contained"
                  >
                    5
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    onClick={() => {
                      setCredit(10);
                      setSelectedPlan("");
                    }}
                    fullWidth
                    variant="contained"
                  >
                    10
                  </Button>
                </Grid>

                <Grid item xs={3}>
                  <Button
                    onClick={() => {
                      setCredit(20);
                      setSelectedPlan("");
                    }}
                    fullWidth
                    variant="contained"
                  >
                    20
                  </Button>
                </Grid>
              </Grid>
            </Box>
            {!isSubscriptionActive && (
              <Divider sx={{ py: 3 }}>
                <Chip label="OR" size="small" />
              </Divider>
            )}
            {!isSubscriptionActive && (
              <Grid sx={{ justifyContent: "center" }} container>
                {plans?.map((el: any) => (
                  <Grid item xs={6}>
                    <Button
                      variant={selectedPlan === el?.pk ? "outlined" : "text"}
                      onClick={() => {
                        setSelectedPlan(el?.pk);
                        setCredit(0);
                      }}
                      sx={{ maxWidth: 345, margin: "0 auto", padding: 2 }}
                    >
                      <CardContent>
                        <Typography variant="h5" component="div" gutterBottom>
                          {el?.name} Plan
                        </Typography>
                        <Typography variant="h6" color="text.primary">
                          ${el?.amount} / month
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {el?.credit_point} Credits Included
                        </Typography>
                      </CardContent>
                    </Button>
                  </Grid>
                ))}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleAddCredit} autoFocus>
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Toolbar>
  );
}
export default function TansactionTable() {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("pk");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [rows, setCreditList] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const handleChangeRefresh = () => {
    setIsRefresh(!isRefresh);
  };
  const GetCreditList = useCallback(async () => {
    try {
      const res = await HttpService.get(API_ROUTES.CreditList);
      setCreditList(res?.data?.data?.transaction_list);
    } catch (error) {}
  }, [isRefresh]);

  React.useEffect(() => {
    GetCreditList();
  }, [GetCreditList]);

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rows, rowsPerPage]
  );

  return (
    <Box sx={{ width: "100%", mt: 5 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          isRefresh={isRefresh}
          handleChangeRefresh={handleChangeRefresh}
          numSelected={selected.length}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 350 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.pk}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" scope="row">
                      {row.pk}
                    </TableCell>
                    <TableCell align="left">{row.transaction_id}</TableCell>
                    <TableCell align="left">{row.transaction_type}</TableCell>
                    <TableCell align="left">{row.amount}</TableCell>
                    <TableCell align="left">
                      {moment(row.transaction_at).format("DD-MM-YYYY")}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
