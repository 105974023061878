import { Box, Button, Grid, TextField } from "@mui/material";
import React from "react";
import useCommonDetails from "../../hooks/useCommonDetails";
import { MuiTelInput } from "mui-tel-input";
import { Formik, FormikValues, FormikHelpers } from "formik";
import * as Yup from "yup";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES } from "../../constant";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { getCurrentAuth, setAuth } from "../../hooks/http-server";
interface FormValues {
  name: string;
  mobile_no: string;
}
export function MyAccount() {
  const userDetails = useCommonDetails();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),

    mobile_no: Yup.string().required("Phone number is required"),
  });
  return (
    <Box sx={{ mt: 3, p: 3 }}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: userDetails?.Name,
          mobile_no: userDetails?.Phone,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("phone_no", values.mobile_no);
            const res = await HttpService.post(
              API_ROUTES.UpdateProfile,
              formData
            );
            if (res) {
              toast.success(res?.data?.data?.message);
              const auth = getCurrentAuth();
              setAuth({ ...auth, Name: values.name, Phone: values.mobile_no });
            }
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({
          errors,
          touched,
          handleSubmit,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          values,
        }) => (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                value={userDetails.Email}
                label="Email"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                autoComplete="given-name"
                name="name"
                required
                fullWidth
                id="name"
                label="Name"
                value={values.name}
                onChange={handleChange}
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiTelInput
                defaultCountry="IN"
                autoComplete="given-mobile_no"
                id="mobile_no"
                name="mobile_no"
                required
                fullWidth
                label="Phone Number"
                value={values.mobile_no}
                onChange={(value) => setFieldValue("mobile_no", value)}
                error={touched.mobile_no && !!errors.mobile_no}
                helperText={touched.mobile_no && errors.mobile_no}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LoadingButton
                onClick={(e: any) => handleSubmit(e)}
                variant="contained"
              >
                Update
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Box>
  );
}
