import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { API_ROUTES, PAGE_ROUTES } from "../constant";
import ChangeRouteTsx from "./changeRoute";

export type authDetailsType = {
  Token: string;
  RefreshToken?: string;
  Email?: string;
  Name: string;
  Phone: string;
};

export const getCurrentAuth = () => {
  const localAuth = localStorage.getItem("auth");
  const auth: authDetailsType = localAuth
    ? JSON.parse(localStorage.getItem("auth") || "")
    : {};
  return auth;
};
export function setAuth(data: authDetailsType) {
  const oldAuth = getCurrentAuth();
  const tempAuth = { ...oldAuth, ...data };
  tempAuth.Token = data.Token;
  tempAuth.Email = data.Email;

  localStorage.setItem("auth", JSON.stringify(tempAuth));
}

export const Logout = () => {
  localStorage.removeItem("auth");
  window.location.href = "/login";
};
const axiosInstance = axios.create();

const refresh = async () => {
  const auth = getCurrentAuth();

  try {
    const ip: any = await fetch("https://api.ipify.org?format=json");
    const ip2 = await ip.json();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `token ${auth.Token}`,
    };
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}${API_ROUTES.RefreshToken}`,
      {
        RefreshToken: auth.RefreshToken,
        ip_address: ip2.ip,
      },
      { headers: headers }
    );
    if (response.data) {
      const body: authDetailsType = response.data.data;
      setAuth(body);
      return body.Token;
    }
  } catch (error: any) {
    // dispatch(logout());

    Logout();
    window.location.href = `${PAGE_ROUTES.Login}?user=true`;
  }
};
// var auth = useTypedSelector(getAuthState, shallowEqual);
// const dispatch = useDispatch();
let flag = false;
const refreshExpiredTokenClosure = () => {
  let isCalled = false;
  let runningPromise: any = undefined;
  return () => {
    if (isCalled && flag) {
      return runningPromise;
    } else {
      isCalled = true;
      flag = true;
      runningPromise = refresh();

      return runningPromise;
    }
  };
};

// stores the function returned by refreshExpiredTokenClosure
function shouldRetry(config: any) {
  return config.retries.count < 3;
}
const refreshExpiredToken = refreshExpiredTokenClosure();

axiosInstance.interceptors.request.use(
  // @ts-ignore: Unreachable code error
  (config: AxiosRequestConfig) => {
    const auth = getCurrentAuth();
    if (!config.headers!["Authorization"]) {
      config.headers!["Authorization"] = `token ${auth?.Token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    error.config.retries = error.config.retries || {
      count: 0,
    };
    const originalRequest = error.config;

    // logout user's session if refresh token api responds 401 UNAUTHORIZED

    // if request fails with 401 UNAUTHORIZED status and 'Token has expired' as response message
    // then it calls the api to generate new access token
    if (
      error.response?.data?.data?.message ===
      "Your Free trial Ended. Please Add Credit and try again !"
    ) {
      console.log("first");
      toast.error(ChangeRouteTsx(error.response?.data?.data?.message));
    } else {
      toast.error(error.response?.data?.data?.message);
    }
    if (error.response?.status === 401 && shouldRetry(error.config)) {
      error.config.retries.count += 1;
      const updatedToken = await refreshExpiredToken();
      if (!updatedToken) {
        Logout();
        window.location.href = `${PAGE_ROUTES.Login}?user=true`;
      } else {
        originalRequest.headers["Authorization"] = `bearer ${updatedToken}`;
        flag = false;
        return axiosInstance(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
