export const COLORS = {
  black: "#000",
  white: "#ffffff",
  gray: "#383838",
  videoBackground: "#EDEDED",
  priceGray: "gray",
  link: "#7D9CC0",
  lightGray: "#EDEDED",
  headerBackGroundColor: "#F3F7FB",
  questionButton: "#2B57F4",
  starColor: "#3E66DF",
};
