import React from "react";
import IHomiesFeatures from "./iHomies-features";
import IHomiesHero from "./iHomies-hero";
import IHomiesMetrics from "./iHomies-metrics";
import IHomiesTestimonials from "./iHomies-testimonials";
import IHomiesTrust from "./iHomies-trust";
import IHomiesUnique from "./iHomies-unique";
import ScrollAnimation from "./ScrollView";
import IHomiesFooter from "./iHomies-footer";
import IHomiesHeader from "./iHomies-header";
import { Box } from "@mui/material";

function HomePage() {
  return (
    <Box
      sx={{
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "auto" : "#f8f8f8",
      }}
    >
      <IHomiesHeader />
      <ScrollAnimation>
        <IHomiesHero />
      </ScrollAnimation>
      <ScrollAnimation>
        <IHomiesUnique />
      </ScrollAnimation>

      <IHomiesFeatures />

      {/* <ScrollAnimation>
        <IHomiesMetrics />
      </ScrollAnimation> */}

      <ScrollAnimation>
        <IHomiesTestimonials />
      </ScrollAnimation>
      <ScrollAnimation>
        <IHomiesFooter />
      </ScrollAnimation>
    </Box>
  );
}

export default HomePage;
