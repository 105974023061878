export const PAGE_ROUTES = {
  Login: "/login",
  Register: "/register",
  ForgotPassword: "/forgot-password",
  ResetPassword: "/reset-password",
  Dashboard: "/dashboard",
  Chats: "/chats",
  ChatsCreate: "/view-chat/:id",
  MyAccount: "/my-account",
  Credit: "/credit",
  TermAndCond: "/term-conditions",
  Privacy: "/privacy",
  HomePage: "/",
};
export const USER_ROLES = {
  ADMIN: "admin",
};

export const API_ROUTES = {
  Login: "/accounts/api/login/",
  VerifyOtp: "/accounts/otp_verify/",
  RefreshToken: "/accounts/api/login/",
  Register: "/accounts/register_view/",
  GetAllChats: "/chatbot_app/user/conversations",
  GetAllChatsType: "/chatbot_app/all_chat_roles",
  CreateNewChat: "/chatbot_app/new/chat/:id",
  GetChatDetails: "/chatbot_app/chat_data/:id",
  StartChat: "/chatbot_app/chat_api/:id",
  ForgotPassoward: "/accounts/forgot_password/",
  UpdatePassword: "/accounts/update_password/",
  UpdateProfile: "/accounts/update_profile/",
  GetTheme: "/core/theme_api",
  Faq: "/core/faq_api",
  HomePageContent: "/core/home",
  CreditList: "/transactions/all_transactions",
  AddCredit: "/transactions/request_money",
  GetCredit: "/transactions/user_wallet",
  GetPlans: "/transactions/all_plans",
};
export const HEADER_TEXT = {
  [PAGE_ROUTES.Dashboard]: "Dashboard",
  [PAGE_ROUTES.Chats]: "Chats",
  [PAGE_ROUTES.ChatsCreate]: "Create New Chat",
  [PAGE_ROUTES.MyAccount]: "My Account",
};
