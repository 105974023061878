import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Style from "../../style/Style";
import * as Yup from "yup";
import { Formik } from "formik";
import Logo from "../../../assets/imeges/logo192.png";
import { MuiOtpInput } from "mui-one-time-password-input";
import { FormHelperText, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { API_ROUTES, PAGE_ROUTES } from "../../constant";
import HttpService from "../../hooks/Https-services";
import { toast } from "react-toastify";
import { ThemeContext } from "../../context/ThemeContex";
import { ThemeConfig } from "../../types";
// TODO remove, this demo shouldn't need to reset the theme.

export function ResetPassword() {
  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[@$!%*?&]/,
        "Password must contain at least one special character"
      )
      .required("Password is required"),

    otp: Yup.string()
      .length(4, "OTP must be exactly 4 digits")
      .matches(/^[0-9]+$/, "OTP must be a number")
      .required("OTP is required"),
  });
  const { themeSetting }: { themeSetting: ThemeConfig } =
    React.useContext(ThemeContext);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Box
      sx={{
        ...Style?.LoginForm?.MainContainer,
        background: `url(${themeSetting?.login_bg_image})`,
      }}
    >
      <Container
        component="main"
        sx={Style.LoginForm?.SubContainer}
        maxWidth="xs"
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexGrow: 1,
            width: 1,
          }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <KeyboardBackspaceIcon
              sx={{
                color: "text.primary",
              }}
            />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            onClick={() => navigate(PAGE_ROUTES.HomePage)}
            sx={{
              m: 1,
              width: 75,
              height: 75,
              bgcolor: "secondary.main",
              cursor: "pointer",
            }}
          >
            <img
              src={themeSetting?.logo}
              width={"100%"}
              style={{ objectFit: "cover" }}
              alt="logo"
            />
          </Avatar>
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            Reset Password
          </Typography>
          <Formik
            initialValues={{ otp: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              // Handle form submission
              try {
                const formData = new FormData();
                formData.append("otp", values.otp);
                formData.append("password", values.password);
                formData.append("email", location.search?.split("=")[1]);
                const res = await HttpService.post(
                  API_ROUTES.UpdatePassword,
                  formData
                );
                if (res) {
                  navigate(PAGE_ROUTES.Login);

                  toast.success(res?.data?.data?.message);
                }
              } catch (error) {}
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              values,
            }) => (
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 2 }}
              >
                <MuiOtpInput
                  value={values.otp}
                  autoFocus
                  length={4}
                  onChange={(newOtp) => setFieldValue("otp", newOtp)}
                  TextFieldsProps={{
                    placeholder: "0",
                    error: touched.otp && !!errors.otp,
                    autoComplete: "off",
                  }}
                />
                {!!errors.otp && touched.otp && (
                  <FormHelperText sx={{ color: "#EF4444", fontSize: "12px" }}>
                    {errors.otp
                      ? errors.otp
                      : "Invalid code entered. Please try again."}
                  </FormHelperText>
                )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                />

                <FormControlLabel
                  sx={{ visibility: "hidden", height: 2 }}
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Reset Password
                </Button>
              </Box>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
}
