import React, { useCallback, useContext, useEffect, useState } from "react";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES } from "../../constant";
import { ChatTable } from "./ChatTable";
import { LoaderContext } from "../../context/LoaderContext";
import { ChatsData } from "../../models/chats/chat-model";
import { useStoreState } from "easy-peasy";
import { ReduxStoreI } from "../../models/redux-store";

export function Chats() {
  const { handleCloseLoader, handleOpenLoader }: any =
    useContext(LoaderContext);

  const { chats, chatTypes } = useStoreState(
    (stats: ReduxStoreI) => stats.Chats
  );

  return (
    <div>
      <ChatTable chats={chats} chatTypes={chatTypes} />{" "}
    </div>
  );
}
