import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { ReactElement, useState } from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { Cancel } from "@mui/icons-material";

const ViewChat = ({
  chat,
  file_list,
}: {
  chat: string | ReactElement;
  file_list: any[];
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Box>
      <IconButton onClick={() => handleOpen()} sx={{ p: 0 }}>
        <ZoomInIcon
          sx={{
            color: "#7c7c7c",
            fontSize: 22,
          }}
        />
      </IconButton>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ textAlign: "end" }}>
          <IconButton onClick={() => handleClose()}>
            <Cancel />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!!file_list?.length && (
            <Box>
              {file_list?.map((el: any) => {
                return (
                  <img
                    style={{ maxWidth: "400px" }}
                    src={`${el?.file_url}`}
                    alt="image-chat"
                  />
                );
              })}
            </Box>
          )}

          {chat}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default ViewChat;
