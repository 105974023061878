import { Button } from "@mui/material";
import { PAGE_ROUTES } from "../constant";
import { Link } from "react-router-dom";

const ChangeRouteTsx = (message: string) => {
  return (
    <Button
      sx={{
        textTransform: "none",
        textAlign: "start !important",
        alignItems: "flex-start !important",
      }}
    >
      <Link
        to={PAGE_ROUTES.Credit}
        style={{
          textDecoration: "none !important",
        }}
      >
        {message}
      </Link>
    </Button>
  );
};
export default ChangeRouteTsx;
