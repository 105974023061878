import { action, thunk } from "easy-peasy";

import api from "./service";

const Chats = {
  chats: [],
  chatTypes: [],

  set: action((state, payload) => {
    state.chats = payload;
  }),
  setChatTypes: action((state, payload) => {
    state.chatTypes = payload;
  }),

  /* thunks */
  getAll: thunk(async (actions, payload = {}, { dispatch }) => {
    const { audit = true, roles } = payload;
    return await api
      .getAllChats(payload)
      .then((response) => {
        audit && actions.set(response?.data?.data?.all_conversations);

        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log("ERR", error);
        // !audit && dispatch?.app?.audit?.set({ type: "error", log: error });
        return Promise.reject(error);
      });
  }),
  getAllChatType: thunk(async (actions, payload = {}, { dispatch }) => {
    const { audit = true, roles } = payload;
    return await api
      .getAllChatTypes(payload)
      .then((response) => {
        audit && actions.setChatTypes(response?.data?.data?.all_roles);

        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log("ERR", error);
        // !audit && dispatch?.app?.audit?.set({ type: "error", log: error });
        return Promise.reject(error);
      });
  }),
};

export default Chats;
