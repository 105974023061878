import React, { useCallback, useEffect, useState } from "react";
import ChatBlock from "../../common/Components/ChatBlock";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES } from "../../constant";
import { useParams } from "react-router-dom";
import moment from "moment";

export function ChatsCreate() {
  const { id }: any = useParams();
  const [chatDetails, setChatDetails] = useState([] as any);
  const GetChatDetails = useCallback(async () => {
    try {
      const res = await HttpService.get(
        API_ROUTES.GetChatDetails.replace(":id", id?.toString())
      );

      const grouped = res?.data?.data?.ans_list?.reduce(
        (acc: any, item: any) => {
          const date = moment(item.question_time).format("YYYY-MM-DD"); // Extract the date part
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(item);
          return acc;
        },
        {}
      );
      const data = Object.keys(grouped).map((date) => ({
        date,
        chat: grouped[date],
      }));

      setChatDetails(data);
    } catch (error) {}
  }, [id]);
  useEffect(() => {
    GetChatDetails();
  }, [GetChatDetails]);
  return (
    <ChatBlock
      isMyChatBot={true}
      chatDetails={chatDetails}
      GetChatDetails={GetChatDetails}
    />
  );
}
