import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HttpService from "../../../../hooks/Https-services";
import { API_ROUTES, PAGE_ROUTES } from "../../../../constant";
import { LoaderContext } from "../../../../context/LoaderContext";
import { LoadingButton } from "@mui/lab";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EllipsisContent from "../../../../common/Components/EllipsisContent";
import { SvgIconComponent } from "@mui/icons-material";
import { useStoreActions, useStoreState } from "easy-peasy";
import { ReduxStoreI } from "../../../../models/redux-store";
import { toast } from "react-toastify";

export default function CreateChatDialoag({
  isSideBar = false,
  component,
  item,
  index,
  openSidebar,
  filter,
}: any) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [chatType, setChatType] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const handleChange = (role: string) => {
    setChatType(role);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const { chatTypes } = useStoreState((stats: ReduxStoreI) => stats.Chats);
  const { getAll } = useStoreActions((action: ReduxStoreI) => action.Chats);
  const location = useLocation();
  const isDark = theme.palette.mode === "dark" ? true : false;
  const { GetUserCredit }: any = React.useContext(LoaderContext);
  const ActiveStyle = (slug: string) => {
    const styles: SxProps = location.pathname.includes(slug)
      ? {
          bgcolor: isDark ? "secondary.main" : "secondary.light",
          borderRadius: 2,
        }
      : {};
    return styles;
  };
  const handleClose = () => {
    setOpen(false);
    setChatType("");
  };
  const handleCreateNewChat = async () => {
    setIsLoading(true);
    try {
      const res = await HttpService.get(
        API_ROUTES.CreateNewChat.replace(":id", chatType)
      );
      navigate(
        PAGE_ROUTES.ChatsCreate.replace(":id", res?.data?.data?.conversation_id)
      );
      toast.info(res?.data?.message);
      GetUserCredit();
      getAll();
      setIsLoading(false);

      handleClose();
    } catch (error) {
      setIsLoading(false);
      handleClose();
    }
  };
  const IconComponent: SvgIconComponent = item?.icon;
  React.useEffect(() => {
    if (filter) {
      setChatType(filter);
    }
  }, [filter]);
  return (
    <React.Fragment>
      {isSideBar ? (
        <Box key={index} onClick={handleClickOpen}>
          <Tooltip
            title={!openSidebar ? item.label : ""}
            placement="right"
            arrow
          >
            <ListItem
              key={item.label}
              disablePadding
              sx={{
                display: "block",
                ...{ ...ActiveStyle(item.slugIdentifier) },
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: openSidebar ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openSidebar ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <IconComponent sx={{ fontSize: 22, color: "common.white" }} />
                </ListItemIcon>
                {/* <ListItemText primary={item.label} /> */}
                <EllipsisContent
                  text={item.label}
                  variant="body2"
                  maxWidth={200}
                  sx={{
                    opacity: openSidebar ? 1 : 0,
                    color: "common.white",
                    span: {
                      fontSize: "14px",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        </Box>
      ) : component ? (
        <Box onClick={handleClickOpen}>{component}</Box>
      ) : (
        <LoadingButton
          sx={{ whiteSpace: "nowrap" }}
          onClick={handleClickOpen}
          variant="contained"
        >
          Start Conversation
        </LoadingButton>
      )}

      <Dialog
        maxWidth={"md"}
        sx={{
          width: 1,
          ".MuiDialog-paper": {
            width: 1,
            maxWidth: 540,
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: 600 }} variant="subtitle2">
            {"Start a New Chat"}
          </Typography>{" "}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2} justifyContent={"center"}>
              {chatTypes
                ?.filter((el: any) =>
                  filter ? filter === el.instruction_id : el
                )
                .map((el: any) => (
                  <Grid item xs={6} sm={4}>
                    <Button
                      fullWidth
                      sx={{
                        color:
                          theme.palette.mode === "dark"
                            ? "white"
                            : "primary.main",
                        border:
                          chatType == el?.instruction_id
                            ? "2px solid"
                            : "2px solid transferent",
                        borderColor: "primary.main",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      onClick={() => handleChange(el?.instruction_id)}
                    >
                      {/*  eslint-disable-next-line jsx-a11y/alt-text */}
                      <img width={"100%"} src={el?.icon} />
                      {el?.role_name}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3, justifyContent: "center" }}>
          <LoadingButton
            loading={isLoading}
            disabled={!chatType}
            variant="contained"
            onClick={handleCreateNewChat}
            autoFocus
          >
            Start
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
