import { Box, Container, Grid, Typography } from "@mui/material";
import { useContext } from "react";

import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { PAGE_ROUTES } from "../../../constant";
import moment from "moment";
import { ThemeContext } from "../../../context/ThemeContex";

function IHomiesFooter() {
  const navigate = useNavigate();
  const { themeSetting }: any = useContext(ThemeContext);

  const goToPrivacyPolicy = () => {
    navigate(PAGE_ROUTES.Privacy);
  };
  const goToTermAndCondi = () => {
    navigate(PAGE_ROUTES.TermAndCond);
  };
  return (
    <Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            width: "100%",
            padding: "24px 0px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "70%",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Poppins",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "white" : "auto",
                  }}
                >
                  {themeSetting?.title} is an AI powered tool for entrepreneurs
                  interested in buying and selling businesses
                </Typography>

                {/* <Box sx={{ display: "flex", gap: 2 }}>
                  <FacebookIcon />
                  <TwitterIcon />
                  <InstagramIcon />
                </Box> */}

                <Typography
                  variant="body2"
                  sx={{ color: "#485687", fontFamily: "Poppins" }}
                >
                  {themeSetting?.title} ● Copyright © {moment().format("YYYY")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600, fontFamily: "Poppins" }}
                >
                  Pages
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 2,
                    gap: 1,
                  }}
                >
                  <Link
                    onClick={() => {
                      navigate(
                        {
                          pathname: PAGE_ROUTES.HomePage,
                        },
                        {
                          state: {
                            scroll: true,
                          },
                        }
                      );
                    }}
                    to={"why-us-scroll"}
                    offset={-100}
                    spy
                    smooth
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#485687",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                      }}
                    >
                      Why Us?
                    </Typography>{" "}
                  </Link>

                  <Link
                    onClick={() => {
                      navigate(
                        {
                          pathname: PAGE_ROUTES.HomePage,
                        },
                        {
                          state: {
                            scroll: true,
                          },
                        }
                      );
                    }}
                    to={"why-us-scroll"}
                    offset={-100}
                    spy
                    smooth
                  >
                    {" "}
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#485687",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                      }}
                    >
                      What Makes Us Unique
                    </Typography>
                  </Link>

                  {/* <Typography variant="body2" sx={{ color: "#485687" }}>
                    Question Bank
                  </Typography>

                  <Typography variant="body2" sx={{ color: "#485687" }}>
                    About Us
                  </Typography> */}
                  <Link
                    onClick={() => {
                      navigate(
                        {
                          pathname: PAGE_ROUTES.HomePage,
                        },
                        {
                          state: {
                            scroll: true,
                          },
                        }
                      );
                    }}
                    to={"testimonials-scroll"}
                    offset={-100}
                    spy
                    smooth
                  >
                    {" "}
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#485687",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                      }}
                    >
                      Testimonials
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600, fontFamily: "Poppins" }}
                >
                  Terms of use & Privacy Policy
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 2,
                    gap: 1,
                  }}
                >
                  {/* <Typography
                    component={"a"}
                    href="mailto:info@financeprep.io"
                    variant="body2"
                    sx={{
                      color: "#485687",
                      cursor: "pointer",
                      textDecoration: "none",
                      fontFamily: "Poppins",
                    }}
                  >
                    Contact Us
                  </Typography> */}
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#485687",
                      cursor: "pointer",
                      fontFamily: "Poppins",
                    }}
                    onClick={goToTermAndCondi}
                  >
                    Terms and Condition
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#485687",
                      cursor: "pointer",
                      fontFamily: "Poppins",
                    }}
                    onClick={goToPrivacyPolicy}
                  >
                    Privacy Policy
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default IHomiesFooter;
