import { Box, Typography } from "@mui/material";
import React from "react";
import { ThemeContext } from "../../../context/ThemeContex";
import { ThemeConfig } from "../../../types";

export function Footer() {
  const { themeSetting }: { themeSetting: ThemeConfig } =
    React.useContext(ThemeContext);
  return (
    <Box
      sx={{
        pt: 2,
        pb: 1,
        textAlign: "center",
        position: "sticky",
        bottom: 0,
        bgcolor: "common.pageBackground",
      }}
    >
      <Typography variant="body2">
        {themeSetting.title} 2024 - Crafted with ❤️{" "}
        <Typography component={"span"} variant="body2" color={"error"}>
          for Buyers and Sellers
        </Typography>
      </Typography>
    </Box>
  );
}
