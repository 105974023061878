import { Box, Typography, Container } from "@mui/material";
function IHomiesUnique() {
  return (
    <Container maxWidth="lg">
      <Box
        className="why-us-scroll"
        id="why-us-scroll"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
          mb: 5,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: (theme) =>
              theme.palette.mode === "dark" ? "white" : "#02015A",
            fontWeight: 600,
            fontFamily: "Be Vietnam Pro",
          }}
        >
          What makes us unique?
        </Typography>
        <Typography
          sx={{
            color: "#68708C",
            fontSize: { xs: "1rem", md: "1rem" },
            mt: 2,
            fontFamily: "Be Vietnam Pro",
          }}
        >
          Interactive Chatbot Interface, Listing Analysis, Financial
          Forecasting, Legal Document Creation,Customized Deal
          Structuring,Negotiation Suppor,Tailored Recommendations
        </Typography>
      </Box>
    </Container>
  );
}

export default IHomiesUnique;
