// ScrollAnimation.js
import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const ScrollAnimation = ({ children }: any) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animate only once
    threshold: 0.1, // Percentage of the element's visibility before triggering the animation
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};

export default ScrollAnimation;
