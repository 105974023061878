import { NavSectionContainerI } from "../../../types";
import { useUpdateNavSectionContainer } from "./helper";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { AccordianSideBar } from "./AccordianSideBar";
import { SvgIconComponent } from "@mui/icons-material";
import EllipsisContent from "../EllipsisContent";
import CreateChatDialoag from "../../../Pages/Chats/ChatTable/CreateChatDialoag";

export const Sidebar = ({ open }: { open: boolean }) => {
  const navItems = useUpdateNavSectionContainer("admin");
  const location = useLocation();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark" ? true : false;
  const ActiveStyle = (slug: string) => {
    const styles: SxProps = location.pathname.includes(slug)
      ? {
          bgcolor: isDark ? "secondary.main" : "secondary.light",
          borderRadius: 2,
        }
      : {};
    return styles;
  };
  return (
    <>
      {navItems.map((navSection: NavSectionContainerI, index: number) => {
        return (
          <div key={index}>
            {navSection?.heading ? (
              <AccordianSideBar navSection={navSection} open={open} />
            ) : (
              <nav className="flex-grow">
                {navSection.sections
                  .filter((item) => item.show)
                  .map((item, index) => {
                    const IconComponent: SvgIconComponent = item?.icon;
                    return item.slugIdentifier !== "chats" ? (
                      <Link
                        key={index}
                        to={item.path}
                        style={{ textDecoration: "none" }}
                      >
                        <Tooltip
                          title={!open ? item.label : ""}
                          placement="right"
                          arrow
                        >
                          <ListItem
                            key={item.label}
                            disablePadding
                            sx={{
                              display: "block",

                              ...{ ...ActiveStyle(item.slugIdentifier) },
                            }}
                          >
                            <ListItemButton
                              sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",

                                px: 2.5,
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                <IconComponent
                                  sx={{ fontSize: 22, color: "common.white" }}
                                />
                              </ListItemIcon>
                              {/* <ListItemText primary={item.label} /> */}
                              <EllipsisContent
                                text={item.label}
                                variant="body2"
                                maxWidth={200}
                                sx={{
                                  opacity: open ? 1 : 0,
                                  color: "common.white",
                                  span: {
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </ListItemButton>
                          </ListItem>
                        </Tooltip>
                      </Link>
                    ) : (
                      <CreateChatDialoag
                        index={index}
                        item={item}
                        openSidebar={open}
                        isSideBar={true}
                      />
                    );
                  })}
              </nav>
            )}
          </div>
        );
      })}
    </>
  );
};
