import HttpService from "../../hooks/Https-services";
import { API_ROUTES } from "../../constant";

export default (() => {
  return {
    /**
     * Get all users
     */
    getAllChats: () => {
      return HttpService.get(API_ROUTES.GetAllChats);
    },
    getAllChatTypes: () => {
      return HttpService.get(API_ROUTES.GetAllChatsType);
    },
  };
})();
