import { ReactNode } from "react";
import MainContainer from "../MainContainer";
import { getCurrentAuth } from "../../hooks/http-server";
import { Navigate } from "react-router-dom";
import { PAGE_ROUTES } from "../../constant";

export type PrivateRoutesProps = {
  children: ReactNode;
};
const PrivateRoutes: React.FC<PrivateRoutesProps> = ({ children }) => {
  const auth = getCurrentAuth();
  if (!auth.Token) {
    return <Navigate to={PAGE_ROUTES.Login} />;
  }
  return <MainContainer>{children}</MainContainer>;
};
export default PrivateRoutes;
