import React from "react";
import { ReactNode, createContext, useState } from "react";
import { API_ROUTES } from "../constant";
import HttpService from "../hooks/Https-services";
import { getCurrentAuth } from "../hooks/http-server";

export const LoaderContext = createContext(null);
export type LoaderContextProviderProps = {
  children: ReactNode;
};
export const LoaderContextProvider: React.FC<LoaderContextProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [userCredit, setUserCredit] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const GetUserCredit = React.useCallback(async () => {
    try {
      const res = await HttpService.get(API_ROUTES.GetCredit);
      setUserCredit(res?.data?.data?.amount);
    } catch (error) {}
  }, []);
  const auth = getCurrentAuth();
  React.useEffect(() => {
    if (auth.Token) {
      GetUserCredit();
    }
  }, [GetUserCredit]);
  return (
    <LoaderContext.Provider
      value={
        {
          handleCloseLoader: handleClose,
          handleOpenLoader: handleOpen,
          userCredit,
          setUserCredit,
          open,
          GetUserCredit,
        } as any
      }
    >
      {children}
    </LoaderContext.Provider>
  );
};
