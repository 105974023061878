const Style = {
  Container: {
    position: "relative",
    display: "flex",
    overflow: "auto",

    borderRadius: "8px",
    flexDirection: "column",
  },
  MessageBox: {
    backgroundColor: "#f4f4f5",
    px: 2,
    borderRadius: "8px",
  },
  ChatBoxHeader: {
    backgroundColor: "background.default",
    py: 2,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    px: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    top: 0,
    position: "sticky",
  },
  Content: {
    flex: 1,
    mt: 3,
    mx: 1,

    overflow: "auto",
  },
  ChatBoxFooter: {
    py: 2,
    px: 1,
    display: "flex",
    gap: 1.5,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    bottom: 0,
    left: 0,
    right: 0,
    position: "sticky",
  },
};
export default Style;
