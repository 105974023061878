import { Box, Button, Typography, Container } from "@mui/material";
import { useContext } from "react";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { PAGE_ROUTES } from "../../../constant";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContex";

function IHomiesHero() {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate(PAGE_ROUTES.Register);
  };
  const { themeSetting }: any = useContext(ThemeContext);
  return (
    <Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            py: 10,
          }}
        >
          <Box
            sx={{
              border: "1px solid #000",
              backgroundColor: "#000",
              borderRadius: 7,
              p: "4px",
              width: "200px",
            }}
          >
            <Box
              sx={{
                borderRadius: 7,
                border: "1px solid #fff",
                backgroundColor: "#fff",
                // width: "80%",
                p: 0.1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "primary.main",
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                {themeSetting?.title}
              </Typography>
            </Box>
          </Box>{" "}
          <Typography
            variant="h4"
            sx={{
              color: (theme) =>
                theme.palette.mode === "dark" ? "white" : "#02015A",
              fontWeight: 600,
              lineHeight: "46px",
              px: { xs: 0, sm: 10, md: 1 },
              fontFamily: "Be Vietnam Pro",
            }}
          >
            {themeSetting?.title} Your AI-driven tool for buying and selling
            businesses.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#4E5774",
              px: { xs: 0, sm: 20, md: 20 },
              fontSize: { xs: "1rem", md: "1rem" },
              fontFamily: "Be Vietnam Pro",
            }}
          >
            At {themeSetting?.title}, we excel in navigating the complexities of
            business acquisitions, from deal analysis to legal documentation.
          </Typography>
          <Button
            onClick={handleLogin}
            sx={{
              height: "50px",
              width: "fit-content",
              // color: "#fff",
              p: "8px 12px",
              textTransform: "none",
              my: 2,
              fontWeight: 500,
              fontFamily: "Be Vietnam Pro",

              borderRadius: "10px",
            }}
            variant="contained"
          >
            Start
            <ArrowOutwardIcon sx={{ ml: 1 }} fontSize="small" />
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default IHomiesHero;
