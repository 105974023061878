import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Style from "../../style/Style";
import * as Yup from "yup";
import { Formik } from "formik";

import { useNavigate } from "react-router-dom";
import { API_ROUTES, PAGE_ROUTES } from "../../constant";
import HttpService from "../../hooks/Https-services";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import { ThemeContext } from "../../context/ThemeContex";
import { ThemeConfig } from "../../types";
// TODO remove, this demo shouldn't need to reset the theme.

export function ForgotPassword() {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });
  const { themeSetting }: { themeSetting: ThemeConfig } =
    React.useContext(ThemeContext);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        ...Style?.LoginForm?.MainContainer,
        background: `url(${themeSetting?.login_bg_image})`,
      }}
    >
      <Container
        component="main"
        sx={Style.LoginForm?.SubContainer}
        maxWidth="xs"
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexGrow: 1,
            width: 1,
          }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <KeyboardBackspaceIcon
              sx={{
                color: "text.primary",
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            onClick={() => navigate(PAGE_ROUTES.HomePage)}
            sx={{
              m: 1,
              width: 75,
              height: 75,
              bgcolor: "secondary.main",
              cursor: "pointer",
            }}
          >
            <img
              src={themeSetting?.logo}
              width={"100%"}
              style={{ objectFit: "cover" }}
              alt="logo"
            />
          </Avatar>
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            Send OTP
          </Typography>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              // Handle form submission
              try {
                const formData = new FormData();
                formData.append("email", values.email);
                const res = await HttpService.post(
                  API_ROUTES.ForgotPassoward,
                  formData
                );
                if (res) {
                  navigate({
                    pathname: PAGE_ROUTES.ResetPassword,
                    search: `email=${values.email}`,
                  });
                  toast.success(res?.data?.data?.message);
                }
              } catch (error) {}
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
              isSubmitting,
            }) => (
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  autoComplete="email"
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />

                <FormControlLabel
                  sx={{ visibility: "hidden", height: 2 }}
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                >
                  Send OTP
                </Button>
              </Box>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
}
