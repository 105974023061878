import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "../style/theme";
import HttpService from "../hooks/Https-services";
import { API_ROUTES } from "../constant";
import { ThemeConfig } from "../types";
import { LoaderContext } from "./LoaderContext";
import { Backdrop, CircularProgress } from "@mui/material";

const ThemeContext: any = createContext(null);

const ThemeProviderReact = ({ children }: any) => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("mode") === "dark" ? true : false
  ); // State to manage dark mode
  const [themeSetting, setNewTheme] = useState<ThemeConfig>();
  const [isLoading, setIsLoading] = useState(true);
  // Function to toggle dark mode
  const toggleDarkMode = () => {
    localStorage.setItem("mode", darkMode ? "light" : "dark");
    setDarkMode(!darkMode);
  };

  const applyTheme = theme({
    mode: darkMode ? "dark" : "light",
    primary: themeSetting?.primary_color || "#664CCD",
    secondary: themeSetting?.secondary_color || "#30419b",
    darkPrimary: themeSetting?.dark_primary_color || "#664CCD",
    darkSecondary: themeSetting?.dark_secondary_color || "#30419b",
  });
  const GetTheme = useCallback(async () => {
    try {
      const res = await HttpService.get(API_ROUTES.GetTheme);
      setNewTheme(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    GetTheme();
  }, [GetTheme]);
  return (
    <ThemeContext.Provider
      value={{
        toggleDarkMode,
        themeSetting,
        darkMode,
      }}
    >
      <MuiThemeProvider
        theme={{
          ...applyTheme,
        }}
      >
        <CssBaseline />
        {!isLoading && children}
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 2,
            bgcolor: (theme) =>
              theme.palette.mode === "dark" ? "#000" : "#fff",
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeProviderReact, ThemeContext };
