import { useState, useEffect } from "react";

const useIsPhone = () => {
  const [isPhone, setIsPhone] = useState(false);

  useEffect(() => {
    const checkIsPhone = () => {
      setIsPhone(window.matchMedia("(max-width: 767px)").matches);
    };

    // Initial check
    checkIsPhone();

    // Add event listener to update on resize
    window.addEventListener("resize", checkIsPhone);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", checkIsPhone);
    };
  }, []);

  return isPhone;
};

export default useIsPhone;
