import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { Chat, CreditCard, Dashboard } from "@mui/icons-material";
import { NavSectionContainerI } from "../../../types";
import { API_ROUTES, PAGE_ROUTES } from "../../../constant";
import { useState, useCallback, useEffect } from "react";
import HttpService from "../../../hooks/Https-services";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import moment from "moment";
import { ReduxStoreI } from "../../../models/redux-store";
import { useStoreActions, useStoreState } from "easy-peasy";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import chats from "../../../models/chats";
import PersonIcon from "@mui/icons-material/Person";
export const navSectionContainer: NavSectionContainerI[] = [
  {
    heading: "",
    sections: [
      {
        icon: Dashboard,
        show: true,
        label: "Dashboard",
        path: PAGE_ROUTES.Dashboard,
        slugIdentifier: "dashboard",
      },
      {
        icon: Chat,
        show: true,
        label: "New Chat",
        path: PAGE_ROUTES.Chats,
        slugIdentifier: "chats",
      },
    ],
  },

  {
    heading: "",
    sections: [
      {
        icon: PersonIcon,
        show: true,
        label: "My Account",
        path: PAGE_ROUTES.MyAccount,
        slugIdentifier: "my-account",
      },
    ],
  },
  {
    heading: "",
    sections: [
      {
        icon: CreditCard,
        show: process.env.REACT_APP_FEARURE === "true" ? true : false,
        label: "Credit",
        path: PAGE_ROUTES.Credit,
        slugIdentifier: "credit",
      },
    ],
  },
];
// interface VisibilityMap {
//   [key: string]: string[];
// }
export const useUpdateNavSectionContainer = (userRole: string) => {
  const { chats } = useStoreState((stats: ReduxStoreI) => stats.Chats);

  const sideBarItem = navSectionContainer.map((section) => {
    return {
      ...section,
      sections: section.sections.map((item) => {
        // Define which roles can see each item
        // const visibilityMap: VisibilityMap = {
        //   customer: [USER_ROLES.ADMIN],
        //   home: [USER_ROLES.ADMIN],
        //   shipments: [USER_ROLES.ADMIN],
        //   "inquiry-view": [USER_ROLES.ADMIN],
        //   delivery: [USER_ROLES.ADMIN],
        //   "pick-up": [USER_ROLES.ADMIN],

        //   consignee: [USER_ROLES.ADMIN],
        //   seaport: [USER_ROLES.ADMIN],
        //   airport: [USER_ROLES.ADMIN],
        //   asset: [USER_ROLES.ADMIN],
        //   employee: [USER_ROLES.ADMIN],
        //   // other slugIdentifier: ["roles"] mappings can be added here
        // };

        // Default show to true if not specified in visibilityMap
        // const allowedRoles = visibilityMap[item.slugIdentifier] || [
        //   USER_ROLES.ADMIN,
        // ];

        return {
          ...item,
          show: item.show === false ? false : true,
          //  allowedRoles.includes(userRole),
        };
      }),
    };
  });
  sideBarItem.push({
    heading: "History",
    sections: chats
      ?.sort((a: any, b: any) => (a.created_at < b.created_at ? 1 : -11))
      ?.map((el: any, index: number) => ({
        icon: ChatBubbleOutlineIcon,
        show: true,
        label: `Chat ${chats?.length - index} (${moment(el.created_at).format(
          "DD MMM, YYYY [at] hh:mm a"
        )})`,
        path: PAGE_ROUTES.ChatsCreate.replace(":id", el.pk),
        slugIdentifier: `/view-chat/${el.pk}`,
      })),
  });
  return sideBarItem;
};
