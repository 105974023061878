import { ReactNode } from "react";
import { getCurrentAuth } from "../../hooks/http-server";
import { Navigate } from "react-router-dom";
import { PAGE_ROUTES } from "../../constant";

export type PublicRoutesProps = {
  children: ReactNode;
};
const PublicRoutes: React.FC<PublicRoutesProps> = ({ children }) => {
  const auth = getCurrentAuth();
  if (auth.Token) {
    return <Navigate to={PAGE_ROUTES.Dashboard} />;
  }
  return <>{children}</>;
};
export default PublicRoutes;
