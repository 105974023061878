import "./App.css";
import { Route, Routes } from "react-router-dom";
import { SignIn } from "./Pages/Login";
import { SignUp } from "./Pages/Register";
import { PAGE_ROUTES } from "./constant";
import { ThemeContext, ThemeProviderReact } from "./context/ThemeContex";
import { ForgotPassword } from "./Pages/ForgotPassword";
import { ResetPassword } from "./Pages/ResetPassword";
import PrivateRoutes from "./common/PrivateRoute";
import { Dashboard } from "./Pages/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Chats } from "./Pages/Chats";
import { ChatsCreate } from "./Pages/ChatsCreate";
import { MyAccount } from "./Pages/MyAccount";
import PublicRoutes from "./common/PublicRoutes";
import { Loader } from "./common/Components/Loader";
import { Helmet } from "react-helmet";
import { useContext, useEffect, useState } from "react";
import { Credit } from "./Pages/Credit";
import HomePage from "./Pages/Homepage";
import Hotjar from "@hotjar/browser";
import TermsAndConditions from "./Pages/Term&Condition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
function App() {
  const [appData, setAppData] = useState({
    favicon: "",
    logo: "",
    manifest: "",
    description: "",
    title: "",
    url: "",
  });
  const { themeSetting }: any = useContext(ThemeContext);
  useEffect(() => {
    setAppData({
      ...appData,
      favicon: themeSetting?.favicon,
      logo: themeSetting?.logo,
      manifest: themeSetting?.manifest,
      description: themeSetting?.description,
      title: themeSetting?.title,
      url: themeSetting?.url,
    });
  }, [themeSetting]);
  useEffect(() => {
    const siteId: any = process.env.REACT_APP_HOTZAR;
    const hotjarVersion = 6;

    // Check if Hotjar is not already initialized
    Hotjar.init(siteId, hotjarVersion);

    // Initializing with `debug` option:
    Hotjar.init(siteId, hotjarVersion, {
      debug: true,
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={appData.favicon} />
        <link rel="apple-touch-icon" href={appData.logo} />
        <link rel="manifest" href={appData.manifest} />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={appData.description} />

        <meta property="og:title" content={appData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={appData.url} />
        <meta property="og:image" content={appData.logo} />
        <meta property="og:description" content={appData.description} />
        <meta property="og:site_name" content={appData.title} />

        <title>{appData.title}</title>
      </Helmet>

      <ToastContainer />
      <Loader />
      <Routes>
        <Route path={PAGE_ROUTES.HomePage} element={<HomePage />} />
        <Route
          path={PAGE_ROUTES.Login}
          element={
            <PublicRoutes>
              <SignIn />
            </PublicRoutes>
          }
        />
        <Route path={PAGE_ROUTES.Privacy} element={<PrivacyPolicy />} />
        <Route
          path={PAGE_ROUTES.TermAndCond}
          element={<TermsAndConditions />}
        />
        <Route
          path={PAGE_ROUTES.Register}
          element={
            <PublicRoutes>
              <SignUp />
            </PublicRoutes>
          }
        />
        <Route
          path={PAGE_ROUTES.ForgotPassword}
          element={
            <PublicRoutes>
              <ForgotPassword />
            </PublicRoutes>
          }
        />
        <Route
          path={PAGE_ROUTES.ResetPassword}
          element={
            <PublicRoutes>
              <ResetPassword />
            </PublicRoutes>
          }
        />
        <Route
          path={PAGE_ROUTES.Dashboard}
          element={
            <PrivateRoutes>
              <Dashboard />
            </PrivateRoutes>
          }
        />{" "}
        <Route
          path={PAGE_ROUTES.Chats}
          element={
            <PrivateRoutes>
              <Chats />
            </PrivateRoutes>
          }
        />
        <Route
          path={PAGE_ROUTES.ChatsCreate}
          element={
            <PrivateRoutes>
              <ChatsCreate />
            </PrivateRoutes>
          }
        />
        <Route
          path={PAGE_ROUTES.MyAccount}
          element={
            <PrivateRoutes>
              <MyAccount />
            </PrivateRoutes>
          }
        />
        <Route
          path={PAGE_ROUTES.Credit}
          element={
            <PrivateRoutes>
              <Credit />
            </PrivateRoutes>
          }
        />
      </Routes>
    </>
  );
}

export default App;
