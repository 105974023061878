import { Typography, Box, Button, Container } from "@mui/material";

import img from "../../../assets/iHomies-img/real_time_data_icon_resized.png";
import img1 from "../../../assets/iHomies-img/Professional_Business_Meeting_500x350.png";
import img2 from "../../../assets/iHomies-img/Slider.svg";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import ScrollAnimation from "../ScrollView";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../../../constant";
import { FEATURE } from "../../../common/Components/FEATURE";

function IHomiesFeatures() {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate(PAGE_ROUTES.Register);
  };
  return (
    <Container maxWidth="lg">
      <ScrollAnimation>
        {/* Interactive Chatbot Interface */}
        <Box
          sx={{
            display: "flex",
            gap: 6,
            my: 12,
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              order: { xs: "2", md: "1" },
            }}
          >
            <FEATURE />
            <Typography
              variant="h5"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark" ? "white" : "#02015A",
                lineHeight: "44px",
                fontWeight: 600,
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Interactive Chatbot Interface
            </Typography>
            <Typography
              sx={{
                color: "#68708C",
                fontSize: { xs: "1rem", md: "1rem" },
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Experience the power of AI through a user-friendly chatbot
              interface that intuitively guides you through every step of the
              acquisition process. Engage in natural conversations and get
              instant responses tailored to your needs.
            </Typography>
            <Button
              onClick={handleLogin}
              sx={{
                width: "fit-content",

                textTransform: "none",
                fontFamily: "Be Vietnam Pro",
              }}
              variant="contained"
            >
              Get Started
              <ArrowOutwardIcon />
            </Button>
          </Box>
          <Box
            component="img"
            src={img1}
            sx={{
              borderRadius: 2.5,
              objectFit: "cover",
              order: { xs: "1", md: "2" },
              minWidth: "500px",
            }}
          ></Box>
        </Box>
      </ScrollAnimation>
      {/*  Listing Analysis */}
      <ScrollAnimation>
        <Box
          sx={{
            display: "flex",
            gap: 6,
            my: 12,
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={img}
            sx={{ order: "1", minWidth: "500px", borderRadius: 2.5 }}
          ></Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              order: "2",
            }}
          >
            <FEATURE />
            <Typography
              variant="h5"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark" ? "white" : "#02015A",
                lineHeight: "44px",

                fontWeight: 600,
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Listing Analysis
            </Typography>
            <Typography
              sx={{
                color: "#68708C",
                fontSize: { xs: "1rem", md: "1rem" },
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Effortlessly analyze business listings. Evaluate financials,
              market position, and growth potential, enabling you to make
              informed decisions.
            </Typography>
            <Button
              onClick={handleLogin}
              sx={{
                width: "fit-content",

                textTransform: "none",
                fontFamily: "Be Vietnam Pro",
              }}
              variant="contained"
            >
              Get Started
              <ArrowOutwardIcon />
            </Button>
          </Box>
        </Box>
      </ScrollAnimation>
      <ScrollAnimation>
        {/* Financial Forecasting */}
        <Box
          sx={{
            display: "flex",
            gap: 6,
            my: 12,
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              order: { xs: "2", md: "1" },
            }}
          >
            <FEATURE />
            <Typography
              variant="h5"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark" ? "white" : "#02015A",
                lineHeight: "44px",

                fontWeight: 600,
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Financial Forecasting
            </Typography>
            <Typography
              sx={{
                color: "#68708C",
                fontSize: { xs: "1rem", md: "1rem" },
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Make data-driven decisions with our financial forecasting feature.
              The chatbot provides projections based on historical data, helping
              you understand potential returns on investment and future
              financial performance.
            </Typography>
            <Button
              onClick={handleLogin}
              sx={{
                width: "fit-content",

                textTransform: "none",
                fontFamily: "Be Vietnam Pro",
              }}
              variant="contained"
            >
              Get Started
              <ArrowOutwardIcon />
            </Button>
          </Box>
          <Box
            component="img"
            src={img1}
            sx={{
              borderRadius: 2.5,
              objectFit: "cover",
              order: { xs: "1", md: "2" },
              minWidth: "500px",
            }}
          ></Box>
        </Box>
      </ScrollAnimation>
      {/*  Listing Analysis */}
      <ScrollAnimation>
        <Box
          sx={{
            display: "flex",
            gap: 6,
            my: 12,
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={img}
            sx={{ order: "1", minWidth: "500px", borderRadius: 2.5 }}
          ></Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              order: "2",
            }}
          >
            <FEATURE />
            <Typography
              variant="h5"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark" ? "white" : "#02015A",
                lineHeight: "44px",

                fontWeight: 600,
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Legal Document Creation
            </Typography>
            <Typography
              sx={{
                color: "#68708C",
                fontSize: { xs: "1rem", md: "1rem" },
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Streamline your legal processes with our document generation
              feature. The chatbot assists in creating essential legal documents
              quickly and accurately.
            </Typography>
            <Button
              onClick={handleLogin}
              sx={{
                width: "fit-content",

                textTransform: "none",
                fontFamily: "Be Vietnam Pro",
              }}
              variant="contained"
            >
              Get Started
              <ArrowOutwardIcon />
            </Button>
          </Box>
        </Box>
      </ScrollAnimation>
      <ScrollAnimation>
        {/*  Customized Deal Structuring */}
        <Box
          sx={{
            display: "flex",
            gap: 6,
            my: 12,
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              order: { xs: "2", md: "1" },
            }}
          >
            <FEATURE />
            <Typography
              variant="h5"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark" ? "white" : "#02015A",
                lineHeight: "44px",

                fontWeight: 600,
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Customized Deal Structuring
            </Typography>
            <Typography
              sx={{
                color: "#68708C",
                fontSize: { xs: "1rem", md: "1rem" },
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Expert assistance in structuring your deals. Recommendations on
              optimal deal structures based on your goals and circumstances.
            </Typography>
            <Button
              onClick={handleLogin}
              sx={{
                width: "fit-content",

                textTransform: "none",
                fontFamily: "Be Vietnam Pro",
              }}
              variant="contained"
            >
              Get Started
              <ArrowOutwardIcon />
            </Button>
          </Box>
          <Box
            component="img"
            src={img1}
            sx={{
              borderRadius: 2.5,
              objectFit: "cover",
              order: { xs: "1", md: "2" },
              minWidth: "500px",
            }}
          ></Box>
        </Box>
      </ScrollAnimation>
      {/*  Negotiation Support */}
      <ScrollAnimation>
        <Box
          sx={{
            display: "flex",
            gap: 6,
            my: 12,
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={img}
            sx={{ order: "1", minWidth: "500px", borderRadius: 2.5 }}
          ></Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              order: "2",
            }}
          >
            <FEATURE />
            <Typography
              variant="h5"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark" ? "white" : "#02015A",
                lineHeight: "44px",

                fontWeight: 600,
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Negotiation Support
            </Typography>
            <Typography
              sx={{
                color: "#68708C",
                fontSize: { xs: "1rem", md: "1rem" },
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Navigate complex negotiations with confidence. Our chatbot
              provides you with strategic questions and tactics, equipping you
              to handle discussions effectively and secure favorable terms.
            </Typography>
            <Button
              onClick={handleLogin}
              sx={{
                width: "fit-content",

                textTransform: "none",
                fontFamily: "Be Vietnam Pro",
              }}
              variant="contained"
            >
              Get Started
              <ArrowOutwardIcon />
            </Button>
          </Box>
        </Box>
      </ScrollAnimation>
      <ScrollAnimation>
        {/* Tailored Recommendations */}
        <Box
          sx={{
            display: "flex",
            gap: 6,
            my: 12,
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              order: { xs: "2", md: "1" },
            }}
          >
            <FEATURE />
            <Typography
              variant="h5"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark" ? "white" : "#02015A",
                lineHeight: "44px",

                fontWeight: 600,
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Tailored Recommendations
            </Typography>
            <Typography
              sx={{
                color: "#68708C",
                fontSize: { xs: "1rem", md: "1rem" },
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Receive personalized recommendations based on your preferences.
              The chatbot analyzes data and offers guidance on the best
              opportunities for buying or selling businesses, ensuring you never
              miss a beat.
            </Typography>
            <Button
              onClick={handleLogin}
              sx={{
                width: "fit-content",

                textTransform: "none",
                fontFamily: "Be Vietnam Pro",
              }}
              variant="contained"
            >
              Get Started
              <ArrowOutwardIcon />
            </Button>
          </Box>
          <Box
            component="img"
            src={img1}
            sx={{
              borderRadius: 2.5,
              objectFit: "cover",
              order: { xs: "1", md: "2" },
              minWidth: "500px",
            }}
          ></Box>
        </Box>
      </ScrollAnimation>
    </Container>
  );
}

export default IHomiesFeatures;
