import {
  Avatar,
  Box,
  Button,
  Chip,
  createTheme,
  IconButton,
  InputAdornment,
  Link,
  Skeleton,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { animateScroll } from "react-scroll";
import { Container } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import Style from "./ChatBlock.style";

import {
  BackHand,
  Download,
  Refresh,
  Send,
  ViewList,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";
import { API_ROUTES, PAGE_ROUTES } from "../../../constant";
import HttpService from "../../../hooks/Https-services";
import moment from "moment";
import ViewChat from "./ViewChats";
const ChatBlock: FC<{
  isMyChatBot: boolean;
  chatDetails: any;
  GetChatDetails: any;
}> = (prop) => {
  const { isMyChatBot, chatDetails, GetChatDetails } = prop;
  const [quetion, setQuetion] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const elementRef = useRef<any>();
  const response = [
    {
      role: "chatmatrix",
      answer: "Welcome ☺️",
    },
  ];
  const [conversationId, setConversationId] = useState("");
  const { id }: any = useParams();
  const [anyalistData, setAnyalistcData] = useState(0);
  const [convo, setConvo] = useState<{ role: string; answer: string }[]>([]);
  const [chatDetailsNew, setChatDetails] = useState<any>([]);
  const [messageLimitHit, setMessageLimitHit] = useState();
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState({
    profile: "",
    isProfile: false,
  });
  const [message, setMessage] = useState("");
  const [suggestedQuestion, setSuggestedQuestion] = useState([]);
  const [userMessageColor, setUserMessageColor] = useState({
    color: "white",
    bg: "black",
  });
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const theme = useTheme();
  const handleChange = (e: any) => {
    if (e.target.value.length <= 2000) {
      setQuetion(e.target.value);
    } else {
      setQuetion(e.target.value.substring("", 2000));
    }
  };

  const handleScroll = () => {
    animateScroll.scrollToBottom({
      containerId: "options-holder",
      duration: "300",
    });
  };

  const handleSendMessage = async () => {
    if (!quetion) {
      return;
    }
    try {
      setLoading(true);

      const quetionObj = {
        date: "",
        chat: [
          {
            question: quetion,
            answer: '<div class="typing-loader"></div>',
            type: "loader",
          },
        ],
      };

      setChatDetails([...chatDetailsNew, quetionObj]);

      setQuetion("");
      setTimeout(() => {
        handleScroll();
        //
      }, 1);
      const formData = new FormData();
      formData.append("message", quetion);
      const res = await HttpService.post(
        API_ROUTES.StartChat?.replace(":id", id),
        formData
      );
      await GetChatDetails();
      setLoading(false);
      setTimeout(() => {
        handleScroll();
        //
      }, 1);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setChatDetails(chatDetails);
    setTimeout(() => {
      handleScroll();
      //
    }, 1);
  }, [chatDetails]);

  // const GetConfigure = useCallback(async () => {
  //   setIsSkeleton(true);
  //   const res = await client.get(
  //     API_ROUTES.GetConfigureDetails.replace(":id", id)
  //   );

  //   if (res?.data?.data) {
  //     const chatSettings = res?.data?.data;
  //     setConvo([
  //       {
  //         role: "chatmatrix",
  //         answer:
  //           chatSettings.Configuration.Interface.InitialMessages.join("\n"),
  //       },
  //     ]);

  //     if (!chatSettings?.Configuration?.Interface?.isRemoveProfilePic) {
  //       setProfileImage({
  //         profile: chatSettings?.Configuration?.Interface?.ChatbotProfilePic,
  //         isProfile:
  //           !chatSettings?.Configuration?.Interface?.isRemoveProfilePic,
  //       });
  //     }
  //     if (
  //       chatSettings?.Configuration?.Interface?.SuggestedMessages?.length > 0
  //     ) {
  //       setSuggestedQuestion(
  //         chatSettings?.Configuration?.Interface?.SuggestedMessages || []
  //       );
  //     }
  //     setMessageLimitHit(
  //       chatSettings?.Configuration?.Basic?.RateLimitErrorMessage
  //     );
  //     if (chatSettings?.Configuration?.Interface?.UserMessageColor) {
  //       setUserMessageColor({
  //         color:
  //           chatSettings?.Configuration?.Interface?.Theme === "dark"
  //             ? "white"
  //             : "black",
  //         bg:
  //           chatSettings?.Configuration?.Interface?.UserMessageColor || "black",
  //       });
  //     }
  //     const darkTheme = createTheme({
  //       palette: {
  //         mode: chatSettings?.Configuration?.Interface?.Theme,
  //       },
  //     });
  //     setTheme(darkTheme);
  //     if (chatSettings?.Configuration?.Interface?.DisplayName) {
  //       setDisplayName(
  //         chatSettings?.Configuration?.Interface?.DisplayName || ""
  //       );
  //     }
  //     setIsSkeleton(false);
  //   }
  //   setIsSkeleton(false);
  // }, []);
  const emailPattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
  const urlPattern =
    /(?:^|\s)((?:www\.[^\s/$.?#]+\.[^\s]+)|(?:https?:\/\/[^\s/$.?#]+\.[^\s]+))/g;
  const replaceLinks = (text: any) => {
    const replacedText = text?.replace(urlPattern, "[$1]($1)");
    return replacedText?.replace(emailPattern, "[$1](mailto:$1)");
  };
  const renderLink = (props: any) => {
    console.log(props);
    const { href, children } = props;
    let cleanedHref = href?.replace(/\.*$/, "");

    if (cleanedHref.startsWith("mailto:")) {
      const email = cleanedHref.substring(7);
      return (
        <a href={cleanedHref} target="_blank" rel="noopener noreferrer">
          {email}
        </a>
      );
    }

    if (cleanedHref.startsWith("www.")) {
      cleanedHref = "https://" + cleanedHref;
    }

    return (
      <a href={cleanedHref} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  // const GetChatBotMessageLimit = async () => {
  //   try {
  //     const res = await client.get(
  //       API_ROUTES.AnylisticForMessage.replace(":id", id)
  //     );
  //     const leftMessage = res.data.data.remainMessages;

  //     if (leftMessage <= 0) {
  //       setAnyalistcData(0);
  //     } else {
  //       setAnyalistcData(leftMessage);
  //     }
  //   } catch (error) {}
  // };
  // const createConversation = async () => {
  //   try {
  //     const res = await client.post(
  //       APIRoutes.CreateConversation.replace(":id", id)
  //     );
  //     if (res?.data?.data?.conversation_sid) {
  //       setConversationId(res.data.data.conversation_sid);
  //     }
  //   } catch (error) {}
  // };
  useEffect(() => {
    if (id) {
      // createConversation();
      // GetChatBotMessageLimit();
      // GetConfigure();
    }
    setConvo(response);
  }, [refresh]);

  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const htmlRef: any = useRef(null);

  // Function to copy the HTML content
  const copyToClipboard = (text: any) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });

    toast.success("Copied Successfully");
  };
  const onVerify = useCallback((token: any) => {
    setToken(token);
  }, []);

  const doSomething = () => {
    /* do something like submit a form and then refresh recaptcha */
    setRefreshReCaptcha((r) => !r);
  };
  const handleCopy = (answer: string) => {
    navigator.clipboard.writeText(answer);
    toast.success("Copied Successfully");
  };
  useEffect(() => {}, [token]);
  if (isSkeleton) {
    return <Skeleton variant="rectangular" height={600} animation="wave" />;
  }

  // const groupedData = groupByDate(chatDetailsNew);
  // console.log(groupedData);
  return (
    <Container
      sx={{
        ...Style.Container,
        backgroundColor: "common.pageBackground",

        maxWidth: isMyChatBot ? "100% !important" : "1600px !important",
        width: 1,
        flexGrow: 1,

        height: 1,
        maxHeight: "82vh",
        border: "1px solid #ccc",
      }}
      disableGutters
    >
      <Box
        sx={{
          ...Style.ChatBoxHeader,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <IconButton onClick={() => navigate(-1)}>
            <KeyboardBackspaceIcon
              sx={{
                color: "text.primary",
              }}
            />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ fontWeight: 800, color: "text.primary" }}
          >
            {displayName}
          </Typography>
        </Box>

        {/* <IconButton onClick={() => setRefresh(!refresh)}>
            <Refresh
              sx={{
                color: "text.primary",
              }}
            />
          </IconButton> */}
      </Box>

      <Box id="options-holder" sx={{ ...Style.Content }}>
        {chatDetailsNew?.map((element: any, ind: number) => {
          return (
            <Box>
              {element?.date && (
                <Stack
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <Chip
                    color="primary"
                    sx={{ my: 1, bgcolor: "common.primary" }}
                    label={moment(element?.date).format("ddd, D MMM")}
                  />
                </Stack>
              )}

              {element?.chat?.map((el: any, index: number) => (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box
                    key={index + 1}
                    ref={elementRef}
                    sx={{
                      display: "flex",
                      width: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box
                      sx={{
                        ...Style.MessageBox,
                        overflow: "auto",
                        "::-webkit-scrollbar": {
                          display: "none",
                        },
                        py: 1,
                        maxWidth: { xs: "70%", md: "50%" },
                        color: userMessageColor.color,
                        backgroundColor: userMessageColor.bg,
                      }}
                    >
                      <ReactMarkdown components={{ a: renderLink }}>
                        {replaceLinks(el.question)}
                      </ReactMarkdown>

                      <Stack sx={{ alignItems: "flex-end", ml: 5 }}>
                        <Typography variant="caption" sx={{ color: "#7c7c7c" }}>
                          {moment(el?.question_time).format("hh:mm A")}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                  <Box
                    key={index + 1}
                    ref={elementRef}
                    sx={{
                      display: "flex",
                      width: 1,
                      justifyContent:
                        el.role == "user" ? "flex-end" : "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        ...Style.MessageBox,
                        overflow: "auto",
                        "::-webkit-scrollbar": {
                          display: "none",
                        },
                        maxWidth: { xs: "70%", md: "50%" },
                        py: 1,

                        backgroundColor:
                          theme.palette.mode === "dark" ? "#292929" : "#f4f4f5",
                      }}
                    >
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {el?.answer_time && (
                          <ViewChat
                            chat={
                              moment(el?.answer_time).isBefore(
                                moment().add("d", -1)
                              ) ? (
                                <Box
                                  dangerouslySetInnerHTML={{
                                    __html: el.answer,
                                  }}
                                ></Box>
                              ) : (
                                <ReactMarkdown components={{ a: renderLink }}>
                                  {replaceLinks(el.answer)}
                                </ReactMarkdown>
                              )
                            }
                            file_list={el?.file_list}
                          />
                        )}
                      </Box>
                      {!!el?.file_list?.length && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            my: 1,
                          }}
                        >
                          {el?.file_list?.map((el: any) => {
                            return (
                              <Box
                                sx={{
                                  position: "relative",
                                  width: "fit-content",
                                }}
                              >
                                <img
                                  style={{ maxWidth: "400px" }}
                                  src={`${el?.file_url}`}
                                  alt="image-chat"
                                />
                                <IconButton
                                  href={el?.file_url}
                                  download={true}
                                  target="_blank"
                                >
                                  <Download sx={{ fontSize: 18 }} />
                                </IconButton>
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                      {el?.type === "loader" ? (
                        <Typography
                          component={"div"}
                          dangerouslySetInnerHTML={{ __html: el.answer }}
                        ></Typography>
                      ) : (
                        <Box ref={htmlRef}>
                          {moment(el?.answer_time).isBefore(
                            moment().add("d", -1)
                          ) ? (
                            <Box
                              dangerouslySetInnerHTML={{ __html: el.answer }}
                            ></Box>
                          ) : (
                            <ReactMarkdown components={{ a: renderLink }}>
                              {replaceLinks(el.answer)}
                            </ReactMarkdown>
                          )}
                        </Box>
                      )}

                      <Stack
                        sx={{
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          gap: 0.5,
                          mt: 1,
                        }}
                      >
                        {el?.answer_time && (
                          <IconButton
                            onClick={() =>
                              copyToClipboard(replaceLinks(el.answer))
                            }
                            sx={{ p: 0 }}
                          >
                            <ContentCopyIcon
                              sx={{
                                color: "#7c7c7c",
                                fontSize: 20,
                              }}
                            />
                          </IconButton>
                        )}

                        <Typography variant="caption" sx={{ color: "#7c7c7c" }}>
                          {moment(el?.answer_time).format("hh:mm A")}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          );
        })}
      </Box>
      <Box>
        <Box sx={Style.ChatBoxFooter}>
          <Box sx={{ width: 1 }}>
            <TextField
              onKeyDown={(e) => {
                if (e.shiftKey && e.key === "Enter") {
                  return;
                }
                e.key === "Enter" && handleSendMessage();
              }}
              fullWidth
              disabled={loading}
              value={quetion}
              multiline
              maxRows={4}
              onChange={handleChange}
              placeholder="Write your question here 😊"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LoadingButton
                      loading={loading}
                      onClick={() => handleSendMessage()}
                      variant="contained"
                    >
                      <Send />
                    </LoadingButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default ChatBlock;
