import {
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import EastIcon from "@mui/icons-material/East";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import { Link } from "react-scroll";

import { COLORS } from "../../../common/color";
import { PAGE_ROUTES } from "../../../constant";
import { getCurrentAuth } from "../../../hooks/http-server";
import { ThemeContext } from "../../../context/ThemeContex";
// import { ReduxStoreI } from "../../store/redux-store";
// import { useAuth } from "../../context/AuthContext";
type Anchor = "right";

function IHomiesHeader() {
  const auth = getCurrentAuth();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("");

  const handleTabClick = (tab: any) => {
    setSelectedTab(tab);
  };

  const handleContactClick = () => {
    window.location.href = "mailto:info@financeprep.io";
  };

  const goToTestimonials = () => {
    // navigate(PAGE_ROUTES.testimonials);
  };

  const { themeSetting }: any = useContext(ThemeContext);

  const location = useLocation();

  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      sx={{ width: "300px", display: "flex", flexDirection: "column" }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end !important",
          marginTop: "20px",
        }}
      >
        <Button
          onClick={toggleDrawer(anchor, false)}
          sx={{
            backgroundColor: COLORS.white,
            width: "fit-content",
            color: COLORS.black,
            marginRight: "20px",
            "&:hover": {
              backgroundColor: `${COLORS.black} !important`,
              color: `${COLORS.white} !important`,
            },
          }}
        >
          <CloseIcon />
        </Button>
      </Box>

      <List sx={{ marginTop: "30px" }}>
        {auth.Token ? (
          <>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  toggleDrawer(anchor, false);
                  goToTestimonials();
                }}
              >
                <ListItemText primary={"Testimonials"} />
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          <>
            {" "}
            <Link
              onClick={() => {
                navigate(
                  {
                    pathname: PAGE_ROUTES.HomePage,
                  },
                  {
                    state: {
                      scroll: true,
                    },
                  }
                );
              }}
              to={"why-us-scroll"}
              offset={-100}
              spy
              smooth
            >
              {" "}
              <ListItem>
                <ListItemButton onClick={toggleDrawer(anchor, false)}>
                  <ListItemText primary={"Why Us ?"} />
                </ListItemButton>
              </ListItem>
            </Link>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  toggleDrawer(anchor, false);
                  handleContactClick();
                }}
              >
                <ListItemText primary={"Contact Us"} />
              </ListItemButton>
            </ListItem>
            <Link
              onClick={() => {
                navigate(
                  {
                    pathname: PAGE_ROUTES.HomePage,
                  },
                  {
                    state: {
                      scroll: true,
                    },
                  }
                );
              }}
              to={"testimonials-scroll"}
              offset={-100}
              spy
              smooth
            >
              {" "}
              <ListItem>
                <ListItemButton onClick={toggleDrawer(anchor, false)}>
                  <ListItemText primary={"Testimonials"} />
                </ListItemButton>
              </ListItem>
            </Link>
          </>
        )}
      </List>
      <Divider />

      <List>
        {!auth.Token ? (
          <ListItem>
            <ListItemButton
              onClick={() => {
                navigate(PAGE_ROUTES.Login);
              }}
            >
              <ListItemText primary={"Log in"} />
            </ListItemButton>
          </ListItem>
        ) : (
          <ListItem>
            {/* <ListItemButton onClick={onLogoutClick}>
              <ListItemText primary={"Log out"} />
            </ListItemButton> */}
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <>
      <Box>
        <Container maxWidth="lg">
          <Box
            sx={{
              width: "100%",
              padding: "24px 0px",
              height: "10vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: 18,
                  fontWeight: 800,

                  lineHeight: "20px",
                  textTransform: "uppercase",
                  mr: 2,
                }}
              >
                {themeSetting?.title}
              </Typography>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 4,
                }}
              >
                <>
                  <Link
                    className={`underline-hover ${
                      selectedTab === "why-us-scroll" ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleTabClick("why-us-scroll");
                      navigate(
                        {
                          pathname: PAGE_ROUTES.HomePage,
                        },
                        {
                          state: {
                            scroll: true,
                          },
                        }
                      );
                    }}
                    to={"why-us-scroll"}
                    offset={-100}
                    spy
                    smooth
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "13px",
                        // color: "#485687",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                        color: (theme) =>
                          theme.palette.mode === "dark" ? "white" : "#02015A",
                      }}
                    >
                      Why Us?
                    </Typography>
                  </Link>

                  <Link
                    className={`underline-hover ${
                      selectedTab === "why-us-scroll" ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleTabClick("testimonials-scroll");
                      navigate(
                        {
                          pathname: PAGE_ROUTES.HomePage,
                        },
                        {
                          state: {
                            scroll: true,
                          },
                        }
                      );
                    }}
                    to={"testimonials-scroll"}
                    offset={-100}
                    spy
                    smooth
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        cursor: "pointer",
                        fontSize: "13px",
                        // color: "#485687",
                        fontFamily: "Poppins",
                        color: (theme) =>
                          theme.palette.mode === "dark" ? "white" : "#02015A",
                      }}
                    >
                      Testimonials
                    </Typography>
                  </Link>
                </>

                <Typography
                  component={"a"}
                  href={`mailto:${process.env.REACT_APP_CONTACT_US}`}
                  target="_blank"
                  className={`underline-hover ${
                    selectedTab === "contact-us" ? "selected" : ""
                  }`}
                  sx={{
                    fontWeight: 600,
                    fontSize: "13px",
                    // color: "#485687",
                    cursor: "pointer",

                    fontFamily: "Poppins",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "white" : "#02015A",
                  }}
                >
                  Contact-Us
                </Typography>
              </Box>

              <Box sx={{ display: { xs: "none", md: "flex" }, gap: 1 }}>
                {!auth.Token ? (
                  <Button
                    sx={{
                      fontSize: "13px",
                      fontWeight: 600,
                      borderRadius: "50px",
                      px: "15px",
                      fontFamily: "Poppins",
                    }}
                    variant="contained"
                    onClick={() => {
                      navigate(PAGE_ROUTES.Register);
                    }}
                  >
                    Register
                  </Button>
                ) : (
                  <></>
                )}
                {!auth.Token ? (
                  <Button
                    sx={{
                      fontSize: "13px",
                      fontWeight: 600,
                      borderRadius: "50px",
                      px: "15px",
                      fontFamily: "Poppins",
                    }}
                    variant="contained"
                    onClick={() => {
                      navigate(PAGE_ROUTES.Login);
                    }}
                  >
                    Log in
                  </Button>
                ) : (
                  <></>
                )}
              </Box>
              <Box sx={{ display: { md: "none" } }}>
                {(["right"] as const).map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button
                      onClick={toggleDrawer(anchor, true)}
                      sx={{
                        backgroundColor: COLORS.white,
                        color: COLORS.black,
                        "&:hover": {
                          backgroundColor: `${COLORS.black} !important`,
                          color: `${COLORS.white} !important`,
                        },
                      }}
                    >
                      <MenuIcon />
                    </Button>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                    >
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </Box>
            </Box>
          </Box>{" "}
        </Container>
      </Box>
    </>
  );
}

export default IHomiesHeader;
