import { NavSectionContainerI } from "../../../../types";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps as MuiAccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { SvgIconComponent } from "@mui/icons-material";
import EllipsisContent from "../../EllipsisContent";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));
interface AccordionSummaryProps extends MuiAccordionSummaryProps {
  isOpen: boolean;
}
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{
      ml: !props.isOpen ? 0.5 : "auto",
    }}
    expandIcon={
      <ExpandMoreIcon
        sx={{
          color: "common.lightBlue",
          border: "none",
          fontSize: 24,
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",

  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
export const AccordianSideBar = ({
  navSection,
  open,
}: {
  navSection: NavSectionContainerI;
  open: boolean;
}) => {
  const [expanded, setExpanded] = useState<string | boolean>("panel1");
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark" ? true : false;
  const location = useLocation();
  const ActiveStyle = (slug: string) => {
    const styles: SxProps =
      location.pathname === slug
        ? {
            bgcolor: isDark ? "secondary.main" : "secondary.light",
            borderRadius: 2,
          }
        : {};
    return styles;
  };
  return (
    <Accordion
      sx={{ bgcolor: "transparent", border: "none" }}
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        isOpen={open}
        sx={{
          display: "flex",
          bgcolor: "transparent",
          color: "common.lightBlue",
          border: "none",
          fontSize: 14,
          ".MuiAccordionSummary-root": {
            border: "none",
          },
        }}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        {open && navSection?.heading}
      </AccordionSummary>

      <AccordionDetails sx={{ bgcolor: "transparent", p: 0, border: "none" }}>
        <nav className="flex-grow">
          {navSection.sections
            .filter((item) => item.show)
            .map((item, index) => {
              const IconComponent: SvgIconComponent = item?.icon;
              return (
                <Link
                  key={index}
                  to={item.path}
                  style={{ textDecoration: "none" }}
                >
                  <Tooltip title={!open && item.label} placement="right" arrow>
                    <ListItem
                      key={item.label}
                      disablePadding
                      sx={{
                        display: "block",
                        ...{ ...ActiveStyle(item.slugIdentifier) },
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <IconComponent
                            sx={{ fontSize: 22, color: "common.white" }}
                          />
                        </ListItemIcon>
                        <EllipsisContent
                          text={item.label}
                          variant="body2"
                          maxWidth={200}
                          sx={{
                            opacity: open ? 1 : 0,
                            color: "common.white",
                            span: {
                              fontSize: "14px",
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                </Link>
              );
            })}
        </nav>
      </AccordionDetails>
    </Accordion>
  );
};
