import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const theme = (props) => {
  const isDarkMode = props.mode === "dark";

  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 567,
        md: 768,
        lg: 992,
        xl: 1440,
      },
    },
    palette: {
      mode: props.mode,
      common: {
        black: "#000000",
        white: "#ffffff",
        grey: "#D9D9D9",
        offWhite: "#FAF9F6",
        darkBlue: "#003260",
        lightBlue: "#b9c1eb",
        pageBackground: isDarkMode ? "#121212" : "#FBFBFF",
        green: "#479706",
        orange: "#FBBF24",
        borderColor: props.primary,
      },
      primary: {
        light: props.primary,
        main: props.darkPrimary,
        dark: "#000000",
        contrastText: "#ffffff",
      },
      secondary: {
        light: props.secondary,
        main: props.darkSecondary,
        dark: "#000000",
        contrastText: "#ffffff",
      },
      text: {
        primary: isDarkMode ? "#ffffff" : "#000000",
      },
      error: {
        main: red.A700,
      },
    },
    typography: {
      fontFamily: "SF UI Text Regular !important",
      color: isDarkMode ? "white" : "black",
      lineHeight: 1.3,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightSemibold: 600,
      fontWeightBold: 700,
      h1: {
        fontFamily: "SF UI Text Regular !important",
        fontWeight: 600,
        fontSize: "32px",
        color: isDarkMode ? "#ffffff" : "#000000",
        lineHeight: "48px",
        letterSpacing: "normal",
        "@media (max-width: 768px)": {
          fontSize: "22px",
          lineHeight: "36px",
        },
      },
      h2: {
        fontFamily: "SF UI Text Regular !important",
        fontWeight: 500,
        color: isDarkMode ? "#ffffff" : "#000000",
        fontSize: "24px",
        lineHeight: "36px",
        letterSpacing: "normal",
      },
      h3: {
        fontFamily: "SF UI Text Regular !important",
        fontWeight: 500,
        fontSize: "22px",
        lineHeight: "32px",
        letterSpacing: "normal",
        color: isDarkMode ? "#ffffff" : "#000000",
        "@media (max-width: 768px)": {
          fontSize: "18px",
          lineHeight: "28px",
        },
      },
      subtitle1: {
        fontFamily: "SF UI Text Regular !important",
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "28px",
        letterSpacing: "normal",
        color: isDarkMode ? "#ffffff" : "#000000",
        "@media (max-width: 768px)": {
          fontSize: "18px",
          lineHeight: "28px",
        },
      },
      subtitle2: {
        fontFamily: "SF UI Text Regular !important",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "22px",
        letterSpacing: "normal",
        color: isDarkMode ? "#ffffff" : "#000000",
        "@media (max-width: 768px)": {
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
      body1: {
        fontFamily: "SF UI Text Regular !important",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px",
        color: isDarkMode ? "#ffffff" : "#000000",
      },
      body2: {
        fontFamily: "SF UI Text Regular !important",
        fontWeight: 400,
        fontSize: "14px",
        color: isDarkMode ? "#ffffff" : "#000000",
      },
      button: {
        fontFamily: "SF UI Text Regular !important",
        fontWeight: 400,
        color: isDarkMode ? "#ffffff" : "#000000",
      },
      caption: {
        fontFamily: "SF UI Text Regular !important",
        fontWeight: 400,
        fontSize: "12px",
        color: isDarkMode ? "#ffffff" : "#000000",
      },
      overline: {
        fontFamily: "SF UI Text Regular !important",
        fontWeight: 400,
        color: isDarkMode ? "#ffffff" : "#000000",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            minWidth: "auto",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: props.primary,
            input: {
              fontSize: 14,
            },
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            minWidth: "auto",
            "&:hover": {
              backgroundColor: isDarkMode
                ? props.darkSecondary
                : props.secondary,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
          },
        },
      },
    },
  });
};

export default theme;
